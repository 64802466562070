export const trackUploadedIntialState = {
  url: null,
  blob: null,
  name: null,
  loading: false,
  succes: false
};

export const trackUploadedReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_URL':
      state.url = action.url;
      return state;
    case 'UPDATE_BLOB':
      state.blob = action.blob;
      return state;
    case 'UPDATE_NAME':
      state.name = action.name;
      return state;
    case 'SET_LOADING':
      state.loading = true;
      return state;
    case 'SET_NO_LOADING':
      state.loading = false;
      return state;
    case 'SET_SUCCESS':
      state.success = true;
      return state;
    case 'SET_NO_SUCCESS':
      state.succes = false;
      return state;
    default:
      return state;
  }
};
