import axios from 'axios';
import { ApiRouteSampleBlob } from '../constants/ApiRoutes';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export const fetchSampleAudioFile = async (filename, libraryName) => {
  const { data } = await axios.get(`${API_ENDPOINT}${ApiRouteSampleBlob}`, {
    params: { sample_name: filename },
    responseType: 'blob',
    headers: {
      'User-Id': localStorage.getItem('user_id'),
      'Library-Name': libraryName
    }
  });
  return data;
};
