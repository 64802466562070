/* eslint-disable no-unused-vars */
import './FiltersDialog.css';
import { motion } from 'framer-motion';
import { filtersKeyNameMap } from '../../constants/FiltersInfos';
import FiltersButton from '../Buttons/FiltersButton';
import { drumIcons } from '../../constants/DrumIcons';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef } from 'react';
import delay from '../../lib/delay';

FiltersDialog.propTypes = {
  handleChangeFilterButtons: PropTypes.func,
  filtersButtonsValues: PropTypes.array,
  setIsOpenFilterDialog: PropTypes.func.isRequired
};

function FiltersDialog({ handleChangeFilterButtons, filtersButtonsValues, setIsOpenFilterDialog }) {
  const ref = useRef();
  useEffect(() => {
    const handleClick = async (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        await delay(100);
        setIsOpenFilterDialog(false);
      }
    };
    document.addEventListener('click', handleClick, true);
    return () => document.removeEventListener('click', handleClick, true);
  });
  const isButtonSelected = useCallback(
    (buttonType) => {
      return filtersButtonsValues.includes(buttonType);
    },
    [filtersButtonsValues]
  );
  return (
    <motion.div
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      className="filters-dialog-main-container"
      ref={ref}>
      <FiltersButton
        key={'ALL'}
        name={'ALL'}
        handleChangeFilterButtons={handleChangeFilterButtons}
        type={'clear'}
        isSelected={filtersButtonsValues.length === 0 || filtersButtonsValues.length === 7}
      />
      {filtersKeyNameMap.map((filter) => (
        <FiltersButton
          key={filter.name}
          name={filter.name}
          icon={drumIcons.get(filter.type)}
          handleChangeFilterButtons={handleChangeFilterButtons}
          type={filter.type}
          isSelected={isButtonSelected(filter.type)}
        />
      ))}
    </motion.div>
  );
}

export default FiltersDialog;
