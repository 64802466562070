import { useCallback } from 'react';
import { drumIcons } from '../../constants/DrumIcons';
import './SampleTypeButton.css';
import PropTypes from 'prop-types';
import { filtersKeyNameMap } from '../../constants/FiltersInfos';

SampleTypeButton.propTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func
};

export default function SampleTypeButton({ type, onClick }) {
  const getTypeCompleteName = useCallback(() => {
    const completeName = filtersKeyNameMap.find((value) => value.type === type);
    return completeName.name;
  }, [type]);

  const getDrumIcon = useCallback(() => {
    return drumIcons.get(type);
  }, [type]);
  return (
    <div className="sample-type-button-main-container" onClick={() => onClick()}>
      <div className="sample-type-button-drum-icon-container">
        <img style={{ height: '150%', width: '100%' }} src={getDrumIcon()} />
      </div>
      <div className="sample-type-button-sample-type-container">{getTypeCompleteName()}</div>
    </div>
  );
}
