export const filtersListValues = ['ki', 'sn', 'hho', 'hhc', 'cr', 'rd', 'tom'];

export const filtersKeyNameMap = [
  {
    type: 'ki',
    name: 'KIK'
  },
  {
    type: 'sn',
    name: 'SNA'
  },
  {
    type: 'hho',
    name: 'HHO'
  },
  {
    type: 'hhc',
    name: 'HHC'
  },
  {
    type: 'cr',
    name: 'CRA'
  },
  {
    type: 'rd',
    name: 'RID'
  },
  {
    type: 'tom',
    name: 'TOM'
  }
];
