/* eslint-disable no-unused-vars */
import { useCallback, useState } from 'react';

import PropTypes from 'prop-types';

import { useDropzone } from 'react-dropzone';

import { Alert, Snackbar } from '@mui/material';

import AudioPlayer from '../AudioPlayer/AudioPlayer';

import './UploadAndPlay.css';
import logoAudiofile from '../../assets/logo-filename.svg';

import AddIcon from '@mui/icons-material/Add';
import { useCustomContext } from '../../contexts/customContext';

import axios from 'axios';
import { ApiRouteAudiofile } from '../../constants/ApiRoutes';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

UploadAndPlay.propTypes = {
  setIsFileUploaded: PropTypes.func.isRequired,
  isFileUploaded: PropTypes.bool.isRequired,
  setIsLoadingUploadingFile: PropTypes.func.isRequired,
  setIsMatchingProcessFinished: PropTypes.func.isRequired
};

function UploadAndPlay({
  isFileUploaded,
  setIsFileUploaded,
  setIsLoadingUploadingFile,
  setIsMatchingProcessFinished
}) {
  /* The status message in case of error */
  const [errorMessage, setErrorMessage] = useState('');

  /* Boolean to know when to open the uploaded file success snackbar */
  const [openSuccessFileUpload, setOpenSuccessFileUpload] = useState(false);

  const [openErrorSnackBar, setOpenErrorSnackBar] = useState(false);

  const [isAiffFile, setIsAiffFile] = useState(false);

  const [isHoverUploadButton, setIsHoverUploadButton] = useState(false);

  const { trackUploadedState, trackUploadedDispatcher } = useCustomContext();

  /* Remove the .wav at the end of a string */
  const cutWavEndOfString = (song) => {
    const array = song.split('.');
    return array[0];
  };

  const updateUploadedFile = (newFile) => {
    if (checkFileDroppedBeforeSendToServer(newFile[0]) === 'error') return;
    setIsLoadingUploadingFile(true);
    setIsFileUploaded(false);
    setIsMatchingProcessFinished(false);
    trackUploadedDispatcher({ type: 'UPDATE_NAME', name: newFile[0].name });
    trackUploadedDispatcher({ type: 'UPDATE_URL', url: URL.createObjectURL(newFile[0]) });
    const data = new FormData();
    data.append('file', newFile[0]);
    axios
      .post(`${API_ENDPOINT}${ApiRouteAudiofile}`, data, {
        responseType: 'blob',
        headers: {
          'User-Id': localStorage.getItem('user_id')
        }
      })
      .then(() => {
        setOpenSuccessFileUpload(true);
        setIsLoadingUploadingFile(false);
        setIsFileUploaded(true);
      })
      .catch((error) => {
        setErrorMessage('Error while uploading your file. Please try again later.');
        setIsLoadingUploadingFile(false);
        setOpenErrorSnackBar(true);
      });
  };

  const checkFileDroppedBeforeSendToServer = useCallback((file) => {
    if (file.size < 50000) {
      setErrorMessage('Audio file too short');
      setOpenErrorSnackBar(true);
      return 'error';
    }
    if (file.type === 'audio/x-aiff' || file.type === 'audio/aiff') setIsAiffFile(true);
    return null;
  }, []);

  const { getRootProps, getInputProps, isDragActive, isDragAccept } = useDropzone({
    accept: {
      'audio/mpeg': ['.mp3'],
      'audio/x-wav': ['.wav'],
      'audio/x-aiff': ['.aif', '.aiff', '.aifc']
    },
    onDrop: updateUploadedFile,
    maxFiles: 1,
    noKeyboard: true
  });

  const handleCloseSnackBarUploadFile = (event, reason) => {
    if (reason === 'clickaway') return;
    setOpenSuccessFileUpload(false);
  };

  return (
    <div
      className="uploadandplay-main-container"
      style={{ backgroundColor: isFileUploaded ? '#1A191E' : 'inherit' }}>
      <Snackbar
        open={isAiffFile}
        onClose={() => setIsAiffFile(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={4000}>
        <Alert severity="warning" onClose={() => setIsAiffFile(false)}>
          Some browser cannot play AIFF files.
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSuccessFileUpload}
        autoHideDuration={4000}
        onClose={handleCloseSnackBarUploadFile}>
        <Alert onClose={handleCloseSnackBarUploadFile} severity="success">
          File uploaded successfully
        </Alert>
      </Snackbar>
      <Snackbar open={openErrorSnackBar} autoHideDuration={4000}>
        <Alert severity="error" onClose={() => setOpenErrorSnackBar(false)}>
          {errorMessage}
        </Alert>
      </Snackbar>
      {isFileUploaded && (
        <>
          <div className="upload-and-play-left-container">
            <div className="upload-and-play-logo-container">
              <div className="upload-and-play-logo-border-container">
                <img style={{ width: '80%', height: '80%' }} src={logoAudiofile} alt="logo-icon" />
              </div>
            </div>
            <div className="upload-and-play-song-name-container">
              <div className="upload-and-play-song-name">{trackUploadedState.name}</div>
            </div>
            <div className="upload-and-play-upload-button-container">
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <div className="rounded-button-container">
                  <AddIcon />
                </div>
              </div>
            </div>
          </div>
          <div className="border-component" />
          <div className="upload-and-play-audio-player-main-container">
            <AudioPlayer url={trackUploadedState.url} />
          </div>
        </>
      )}
    </div>
  );
}

export default UploadAndPlay;
