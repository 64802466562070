import { Tooltip, styled, tooltipClasses } from '@mui/material';

const CustomToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#3B3650',
    color: 'white',
    boxShadow: theme.shadows[1],
    fontSize: 15
  }
}));

export default CustomToolTip;
