import './FiltersButton.css';
import PropTypes from 'prop-types';

FiltersButton.propTypes = {
  name: PropTypes.string,
  icon: PropTypes.any,
  handleChangeFilterButtons: PropTypes.func,
  type: PropTypes.string,
  isSelected: PropTypes.bool
};

function FiltersButton({ name, icon, handleChangeFilterButtons, type, isSelected }) {
  return (
    <div
      onClick={() => handleChangeFilterButtons(type)}
      className="filter-button-container"
      style={{ backgroundColor: isSelected ? '#3F4043' : '#28272c' }}>
      {icon && (
        <div className="filter-button-icon-container">
          <img style={{ width: '90%', height: '120%' }} src={icon} alt="drum-icon" />
        </div>
      )}
      <div
        style={{ justifyContent: icon ? 'start' : 'center' }}
        className="filter-button-name-container">
        {name}
      </div>
    </div>
  );
}

export default FiltersButton;
