import './DrumKitMobileToolBar.css';
import PropTypes from 'prop-types';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import CloseIcon from '@mui/icons-material/Close';
import BigRoundedButton from '../../Buttons/BigRoundedButton';
// import ScreenRotationIcon from '@mui/icons-material/ScreenRotation';
import RoundedLogoButton from '../../Buttons/RoundedLogoButton';
import DeleteIconSVG from '../../../assets/delete-icon.svg';
import DownloadIconSVG from '../../../assets/download-icon.svg';
import AutoPopulateIconSVG from '../../../assets/auto-populate-icon.svg';
import CustomSnackBarError from '../../CustomSnackBarError/CustomSnackBarError';
import { useState } from 'react';
import axios from 'axios';
import { useMobileOrientation } from 'react-device-detect';

DrumKitMobileToolBar.propTypes = {
  setIsOpenDrumkit: PropTypes.func,
  isOpenDrumkit: PropTypes.bool,
  setDrumKitListOfSamplesToPlay: PropTypes.func,
  drumKitListOfSamplesToPlay: PropTypes.any,
  currentSampleLibrary: PropTypes.any
};

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function DrumKitMobileToolBar({
  setIsOpenDrumkit,
  isOpenDrumkit,
  setDrumKitListOfSamplesToPlay,
  drumKitListOfSamplesToPlay,
  currentSampleLibrary
}) {
  /* Boolean to activate the alert telling that list of samples is empty when we want to download drumkit */
  const [triggerListOfSamplesEmty, setTriggerListOfSamplesEmpty] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccessAutoPopulate, setIsScucessAutoPopulate] = useState(false);

  const { isLandscape } = useMobileOrientation();

  /* Boolean to know if the auto populate is in loading */
  // const [isLoadingAutoPopulate, setIsLoadingAutoPopulate] = useState(false);

  const getAllBlobsFromAutoPopulateRequest = (data) => {
    const urls = data.map((elem) =>
      axios.get(`${API_ENDPOINT}/sample/`, {
        params: { sample_name: elem.name },
        responseType: 'blob',
        headers: {
          'User-Id': localStorage.getItem('user_id'),
          'Library-Name': currentSampleLibrary.name.toLowerCase()
        }
      })
    );
    axios
      .all(urls)
      .then(
        axios.spread((...responses) => {
          responses.map((response, index) => {
            const url = URL.createObjectURL(response.data);
            setDrumKitListOfSamplesToPlay((drumKitListOfSamplesToPlay) => ({
              ...drumKitListOfSamplesToPlay,
              [index]: {
                name: drumKitListOfSamplesToPlay[index].name,
                tag: drumKitListOfSamplesToPlay[index].tag,
                url: url,
                status: 'success'
              }
            }));
          });
          // setIsLoadingAutoPopulate(false);
          setIsScucessAutoPopulate(true);
        })
      )
      .catch(() => {
        setIsError(true);
        setErrorMessage(
          'Error while getting samples from auto populate option. Please try again later'
        );
      });
  };

  const onClickAutoPopulateButton = () => {
    // setIsLoadingAutoPopulate(true);
    axios
      .get(`${API_ENDPOINT}/drumkit/`, {
        withCredentials: true,
        headers: {
          'User-Id': localStorage.getItem('user_id'),
          'Library-Name': currentSampleLibrary.name.toLowerCase()
        }
      })
      .then((res) => {
        getAllBlobsFromAutoPopulateRequest(res.data);
        setDrumKitListOfSamplesToPlay(res.data);
      })
      .catch(() => {
        // setIsLoadingAutoPopulate(false);
        setIsError(true);
        setErrorMessage('Error while auto populating drumkit. Please try again later');
      });
  };

  const onClickDownloadDrumKitSamples = () => {
    if (drumKitListOfSamplesToPlay.length === 0) setTriggerListOfSamplesEmpty(true);
    else {
      const tmpKeys = Object.keys(drumKitListOfSamplesToPlay);
      const tmpList = tmpKeys.map((value) => drumKitListOfSamplesToPlay[value].name).toString();
      axios
        .get(`${API_ENDPOINT}/download/samples/`, {
          params: { filenames: tmpList },
          withCredentials: true,
          responseType: 'blob',
          headers: {
            'User-Id': localStorage.getItem('user_id'),
            'Library-Name': currentSampleLibrary.name.toLowerCase()
          }
        })
        .then((res) => {
          const url = URL.createObjectURL(res.data);
          let a = document.createElement('a');
          a.href = url;
          a.download = 'samples-match-drumkit';
          a.click();
          URL.revokeObjectURL(url);
        })
        .catch(() => {
          setIsError(true);
          setErrorMessage('Error while downloading sample. Please try again later');
        });
    }
  };

  return (
    <div
      className="drumkit-mobile-toolbar-main-container"
      style={isOpenDrumkit ? { marginLeft: '1%' } : {}}>
      <CustomSnackBarError
        open={triggerListOfSamplesEmty}
        onClose={() => setTriggerListOfSamplesEmpty(false)}
        severity="warning"
        text="Your drumkit is empty !"
      />
      <CustomSnackBarError
        open={isError}
        onClose={() => setIsError(false)}
        severity="error"
        text={errorMessage}
      />
      <CustomSnackBarError
        open={isSuccessAutoPopulate}
        onClose={() => setIsScucessAutoPopulate(false)}
        severity="success"
        text="Successfully autopulated drumkit"
      />
      {isOpenDrumkit ? (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'column'
          }}>
          {!isLandscape && (
            <BigRoundedButton
              text="DRUMKIT"
              icon={<CloseIcon />}
              onClick={() => setIsOpenDrumkit((value) => !value)}
              isSvg={false}
              width="auto"
              height="auto"
              margin="0px"
              padding="0.6em"
            />
          )}
          <RoundedLogoButton
            onClick={() => setDrumKitListOfSamplesToPlay([])}
            icon={DeleteIconSVG}
            borderRadius="10px"
          />
          <RoundedLogoButton
            onClick={onClickAutoPopulateButton}
            icon={AutoPopulateIconSVG}
            borderRadius="10px"
          />
          <RoundedLogoButton
            onClick={onClickDownloadDrumKitSamples}
            icon={DownloadIconSVG}
            borderRadius="10px"
          />
          {/* {!isLandscape && (
            <BigRoundedButton
              icon={<ScreenRotationIcon />}
              onClick={() => null}
              isSvg={false}
              width="auto"
              height="auto"
              margin="0px"
              padding="0.6em"
            />
          )} */}
        </div>
      ) : (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <BigRoundedButton
            text="DRUMKIT"
            icon={<ArrowOutwardIcon />}
            onClick={() => setIsOpenDrumkit((value) => !value)}
            isSvg={false}
            width="auto"
            height="auto"
            margin="0px"
            padding="0.6em"
          />
          {/* <BigRoundedButton
            icon={<ScreenRotationIcon />}
            onClick={() => null}
            isSvg={false}
            width="auto"
            height="auto"
            margin="0px"
            padding="0.6em"
          /> */}
        </div>
      )}
    </div>
  );
}

export default DrumKitMobileToolBar;
