import kickSVG from '../assets/kick.svg';
import snareSVG from '../assets/snare.svg';
import hhcSVG from '../assets/hhc.svg';
import hhoSVG from '../assets/hho.svg';
import rideSVG from '../assets/ride.svg';
import tomSVG from '../assets/tom.svg';
import crashSVG from '../assets/crash.svg';

export const drumIcons = new Map([
  ['ki', kickSVG],
  ['sn', snareSVG],
  ['hhc', hhcSVG],
  ['hho', hhoSVG],
  ['rd', rideSVG],
  ['tom', tomSVG],
  ['cr', crashSVG]
]);
