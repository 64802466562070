import PropTypes from 'prop-types';
import './BigRoundedButton.css';
import { CircularProgress } from '@mui/material';

BigRoundedButton.propTypes = {
  icon: PropTypes.any.isRequired,
  text: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  isSvg: PropTypes.bool.isRequired,
  backgroundColor: PropTypes.string,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
  margin: PropTypes.string,
  padding: PropTypes.string,
  boxShadow: PropTypes.bool,
  isLoading: PropTypes.bool
};

function BigRoundedButton({
  icon,
  text,
  width,
  height,
  onClick,
  isSvg,
  backgroundColor,
  fontSize,
  fontWeight,
  margin,
  padding,
  boxShadow,
  isLoading
}) {
  return (
    <div
      onClick={isLoading ? () => null : () => onClick()}
      className="big-rounded-button-main-container"
      style={{
        width: width ? width : '100%',
        height: height ? height : '100%',
        backgroundColor: backgroundColor && backgroundColor,
        fontSize: fontSize ? fontSize : 'inherit',
        fontWeight: fontWeight ? fontWeight : 'inherit',
        margin: margin && margin,
        padding: padding && padding,
        boxShadow: boxShadow === false && 'none'
      }}>
      {isLoading ? (
        <>
          <CircularProgress style={{ color: 'white', width: '2em', height: '2em' }} />
        </>
      ) : (
        <>
          {isSvg ? <img src={icon} /> : <>{icon}</>}
          <span
            className="big-rounded-button-text"
            style={{
              marginLeft: '0.5em',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}>
            {text}
          </span>
        </>
      )}
    </div>
  );
}

export default BigRoundedButton;
