/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import './DrumKitMobile.css';
import { DrumKitKeyboardQWERTYLabels } from '../../../constants/DrumKitKeyboard';
import PropTypes from 'prop-types';
import { DrumKitKeyboardKeys } from '../../../constants/DrumKitKeyboard';
import DrumPad from '../DrumPad';
import LoadingGIFWithText from '../../LoadingGIF/LoadingGIFWithText';
import DrumPadMobile from './DrumPadMobile';

DrumKitMobile.propTypes = {
  drumKitListOfSamplesToPlay: PropTypes.any,
  setDrumKitListOfSamplesToPlay: PropTypes.func,
  currentSampleLibrary: PropTypes.any.isRequired,
  isLoadingAutoPopulate: PropTypes.bool
};

export default function DrumKitMobile({
  drumKitListOfSamplesToPlay,
  setDrumKitListOfSamplesToPlay,
  currentSampleLibrary,
  isLoadingAutoPopulate
}) {
  const [currentKeyboardKeys] = useState(DrumKitKeyboardQWERTYLabels);

  return (
    <div className="drumkit-mobile-main-container">
      <div className="drumkit-mobile-border-container">
        {!isLoadingAutoPopulate ? (
          <>
            <div className="drum-kit-select-container"></div>
            <div className="drum-kit-pads-container">
              <div className="drum-kit-first-line">
                {currentKeyboardKeys
                  .filter((value, index) => index <= 3)
                  .map((value, index) => {
                    return (
                      <DrumPadMobile
                        key={index}
                        index={index}
                        drumKitListOfSamplesToPlay={drumKitListOfSamplesToPlay}
                        setDrumKitListOfSamplesToPlay={setDrumKitListOfSamplesToPlay}
                        keyboardKey={value}
                        currentSampleLibrary={currentSampleLibrary}
                        volume={0.3}
                      />
                    );
                  })}
              </div>
              <div className="drum-kit-second-line">
                {currentKeyboardKeys
                  .filter((value, index) => index > 3)
                  .map((value, index) => {
                    return (
                      <DrumPadMobile
                        key={index}
                        index={index + 4}
                        drumKitListOfSamplesToPlay={drumKitListOfSamplesToPlay}
                        setDrumKitListOfSamplesToPlay={setDrumKitListOfSamplesToPlay}
                        keyboardKey={value}
                        currentSampleLibrary={currentSampleLibrary}
                        volume={0.3}
                      />
                    );
                  })}
              </div>
            </div>
          </>
        ) : (
          <div className="drum-kit-loading-autopopulate-container">
            <LoadingGIFWithText width="auto" height="100%" text="Auto populating drumkit ..." />
          </div>
        )}
      </div>
    </div>
  );
}
