export const mapKeysToIndexQWERTYLabels = new Map([
  ['q', 0],
  ['w', 1],
  ['e', 2],
  ['r', 3],
  ['a', 4],
  ['s', 5],
  ['d', 6]
]);

export const mapKeysToIndex = new Map([
  ['a', 0],
  ['z', 1],
  ['e', 2],
  ['r', 3],
  ['q', 4],
  ['s', 5],
  ['d', 6]
]);
