import { Alert, Snackbar } from '@mui/material';
import { PropTypes } from 'prop-types';
import './CustomSnackBarError.css';

CustomSnackBarError.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  text: PropTypes.string,
  severity: PropTypes.string
};

function CustomSnackBarError({ open, onClose, text, severity }) {
  return (
    <div>
      <Snackbar autoHideDuration={8000} open={open} onClose={onClose}>
        <Alert onClose={onClose} severity={severity}>
          {text}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default CustomSnackBarError;
