import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import './ListOfSamplesBody.css';
import SampleCellFromListOfSamples from '../SampleCellFromListOfSamples/SampleCellFromListOfSamples';
import LoadingGIFWithText from '../LoadingGIF/LoadingGIFWithText';
import { ViewportList } from 'react-viewport-list';
import { useEffect, useState } from 'react';

ListOfSamplesBody.propTypes = {
  tableEl: PropTypes.any.isRequired,
  isOpenFilterDialog: PropTypes.bool.isRequired,
  isOpenSelectLibraryDropDown: PropTypes.bool.isRequired,
  samplesLoaded: PropTypes.any.isRequired,
  indexOfTrackPlaying: PropTypes.number.isRequired,
  loadingMatchingProcess: PropTypes.bool.isRequired,
  handleClickOnDownloadFromListOfSample: PropTypes.func.isRequired,
  handleClickOnSampleFromListOfSample: PropTypes.func.isRequired,
  isLoadingFetchingNextPage: PropTypes.bool.isRequired,
  isFetchingAudioBlob: PropTypes.bool.isRequired,
  currentSampleLibrary: PropTypes.any.isRequired,
  scrollRefHandler: PropTypes.any,
  paginationSwitch: PropTypes.bool.isRequired,
  currentPageSelected: PropTypes.number.isRequired
};
function ListOfSamplesBody({
  tableEl,
  isOpenFilterDialog,
  isOpenSelectLibraryDropDown,
  samplesLoaded,
  indexOfTrackPlaying,
  loadingMatchingProcess,
  handleClickOnDownloadFromListOfSample,
  handleClickOnSampleFromListOfSample,
  isLoadingFetchingNextPage,
  isFetchingAudioBlob,
  currentSampleLibrary,
  scrollRefHandler,
  paginationSwitch,
  currentPageSelected
}) {
  const [loadingMessage, setLoadingMessage] = useState('Sample matching in progress ...');
  useEffect(() => {
    if (loadingMatchingProcess) {
      setTimeout(() => {
        setLoadingMessage('Almost there ...');
      }, 3000);
    }
  }, [loadingMatchingProcess]);
  return (
    <div
      className="table-body-container"
      ref={tableEl}
      style={{
        display: loadingMatchingProcess || isLoadingFetchingNextPage ? 'flex' : 'block',
        alignItems: 'center',
        justifyContent: 'center',
        filter: (isOpenFilterDialog || isOpenSelectLibraryDropDown) && 'blur(2px)'
      }}>
      {loadingMatchingProcess ? (
        <div>
          <LoadingGIFWithText width="10%" text={loadingMessage} />
        </div>
      ) : isLoadingFetchingNextPage ? (
        <div>
          <LoadingGIFWithText width="10%" />
        </div>
      ) : (
        <>
          <ViewportList
            overflowAnchor="none"
            items={samplesLoaded}
            initialIndex={indexOfTrackPlaying}
            viewportRef={tableEl}
            ref={scrollRefHandler}>
            {(sample, index) => (
              <div className="table-cell-border-container" key={index}>
                <motion.div
                  animate={{ opacity: 1 }}
                  initial={{ opacity: 0 }}
                  className="table-cell-container"
                  style={{
                    backgroundColor: index === indexOfTrackPlaying ? '#3F4043' : '#28272c'
                  }}
                  key={sample}>
                  <SampleCellFromListOfSamples
                    sample={sample}
                    index={index}
                    indexOfTrackPlaying={indexOfTrackPlaying}
                    handleClickOnDownloadFromListOfSample={handleClickOnDownloadFromListOfSample}
                    handleClickOnSampleFromListOfSample={handleClickOnSampleFromListOfSample}
                    isFetchingAudioBlob={isFetchingAudioBlob}
                    paginationSwitch={paginationSwitch}
                    currentSampleLibrary={currentSampleLibrary}
                    currentPageSelected={currentPageSelected}
                  />
                </motion.div>
              </div>
            )}
          </ViewportList>
        </>
      )}
    </div>
  );
}

export default ListOfSamplesBody;
