/* eslint-disable no-unused-vars */
import './UploadCustomLibrary.css';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import { useDropzone } from 'react-dropzone';
import { useState } from 'react';
import { CircularProgress } from '@mui/material';
import CustomSnackBarError from '../CustomSnackBarError/CustomSnackBarError';
import { useCustomContext } from '../../contexts/customContext';
import CustomToolTip from '../CustomToolTip/CustomToolTip';

UploadCustomLibrary.propTypes = {
  onUpload: PropTypes.func,
  text: PropTypes.string.isRequired
};

import axios from 'axios';
import { ApiRouteIndexedSamples } from '../../constants/ApiRoutes';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export default function UploadCustomLibrary({ text }) {
  const [isLoadingUploadingCustomSampleLibrary, setIsLoadingUploadingCustomSampleLibrary] =
    useState(false);
  const [isSuccessUploadingCustomLibrary, setIsSuccessUploadingCustomLibrary] = useState(false);

  const { fetchSampleLibrariesInfos } = useCustomContext();

  const sendCustomSampleLibraryToServer = (file) => {
    var data = new FormData();
    data.append('file', file[0]);
    setIsLoadingUploadingCustomSampleLibrary(true);
    axios
      .post(`${API_ENDPOINT}${ApiRouteIndexedSamples}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'User-Id': localStorage.getItem('user_id'),
          'Folder-Name': 'custom'
        }
      })
      .then((res) => {
        setIsLoadingUploadingCustomSampleLibrary(false);
        fetchSampleLibrariesInfos();
        setIsSuccessUploadingCustomLibrary(true);
      })
      .catch(() => {
        setIsLoadingUploadingCustomSampleLibrary(false);
      });
  };
  const updateUploadedFile = (file) => {
    sendCustomSampleLibraryToServer(file);
  };
  const { getRootProps, getInputProps } = useDropzone({
    accept: { 'application/json': ['.json'] },
    onDrop: updateUploadedFile,
    maxFiles: 1,
    noKeyboard: true
  });
  const item = {
    hidden: { opacity: 0 },
    show: { opacity: 1 }
  };
  return (
    <div className="upload-custom-library-main-container">
      <CustomSnackBarError
        open={isSuccessUploadingCustomLibrary}
        onClose={() => setIsSuccessUploadingCustomLibrary(false)}
        text="Successfully added your library !"
        severity="success"
      />
      <CustomToolTip enterDelay={99} title="Upload the json file containing your indexed samples">
        <motion.div variants={item} className="upload-custom-library-border-container">
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <div className="upload-custom-library-logo-and-name-container">
              {isLoadingUploadingCustomSampleLibrary ? (
                <CircularProgress sx={{ color: 'white', width: '100%' }} />
              ) : (
                <LibraryAddIcon />
              )}
              {text}
            </div>
          </div>
        </motion.div>
      </CustomToolTip>
    </div>
  );
}
