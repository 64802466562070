export const normalizeAudioBuffer = (audioBuffer, maxVolume, audioContext) => {
  const audioData = audioBuffer.getChannelData(0);
  const peak = findPeakVolume(audioData);
  const normalizationFactor = maxVolume / peak;

  // Create a new audio buffer with the normalized data
  const normalizedBuffer = audioContext.createBuffer(
    audioBuffer.numberOfChannels,
    audioBuffer.length,
    audioBuffer.sampleRate
  );

  for (let channel = 0; channel < audioBuffer.numberOfChannels; channel++) {
    const channelData = audioBuffer.getChannelData(channel);
    const normalizedData = new Float32Array(channelData.length);

    for (let i = 0; i < channelData.length; i++) {
      normalizedData[i] = channelData[i] * normalizationFactor;
    }

    normalizedBuffer.getChannelData(channel).set(normalizedData);
  }

  return normalizedBuffer;
};

export const findPeakVolume = (audioData) => {
  let peak = 0;
  for (let i = 0; i < audioData.length; i++) {
    if (Math.abs(audioData[i]) > peak) {
      peak = Math.abs(audioData[i]);
    }
  }
  return peak;
};
