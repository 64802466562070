/* eslint-disable no-unused-vars */
import DrumKit from '../Drumkit/DrumKit';
import PropTypes from 'prop-types';

import { useRef } from 'react';
import './LeftContainer.css';
import WaveFormContainer from '../WaveForm/WaveFormContainer';

LeftContainer.propTypes = {
  drumKitListOfSamplesToPlay: PropTypes.any,
  setDrumKitListOfSamplesToPlay: PropTypes.func,
  isFrenchKeyBoard: PropTypes.bool,
  setIsFrenchKeyBoard: PropTypes.func,
  currentBlobPlayed: PropTypes.any,
  isMatchingProcessFinished: PropTypes.bool,
  shouldDoAutomaticAutoPopulateDrumkit: PropTypes.bool.isRequired,
  setShouldDoAutomaticAutoPopulateDrumkit: PropTypes.func.isRequired,
  currentSampleLibrary: PropTypes.any.isRequired
};

function LeftContainer({
  drumKitListOfSamplesToPlay,
  setDrumKitListOfSamplesToPlay,
  isFrenchKeyBoard,
  setIsFrenchKeyBoard,
  currentBlobPlayed,
  isMatchingProcessFinished,
  shouldDoAutomaticAutoPopulateDrumkit,
  setShouldDoAutomaticAutoPopulateDrumkit,
  currentSampleLibrary
}) {
  const ref = useRef();
  return (
    <div ref={ref} className="left-container-main-container">
      <WaveFormContainer
        currentBlobPlayed={currentBlobPlayed}
        isMatchingProcessFinished={isMatchingProcessFinished}
      />
      <DrumKit
        drumKitListOfSamplesToPlay={drumKitListOfSamplesToPlay}
        setDrumKitListOfSamplesToPlay={setDrumKitListOfSamplesToPlay}
        isFrenchKeyBoard={isFrenchKeyBoard}
        setIsFrenchKeyBoard={setIsFrenchKeyBoard}
        reference={ref}
        isMatchingProcessFinished={isMatchingProcessFinished}
        shouldDoAutomaticAutoPopulateDrumkit={shouldDoAutomaticAutoPopulateDrumkit}
        setShouldDoAutomaticAutoPopulateDrumkit={setShouldDoAutomaticAutoPopulateDrumkit}
        currentSampleLibrary={currentSampleLibrary}
      />
    </div>
  );
}

export default LeftContainer;
