/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import './SampleInfosPopUpContainer.css';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Radar } from 'react-chartjs-2';
import Chart, {
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
} from 'chart.js/auto';
import delay from '../../lib/delay';

Chart.register(RadialLinearScale, Legend, PointElement, LineElement, Filler, Tooltip);

SampleInfosPopUpContainer.propTypes = {
  sample: PropTypes.any.isRequired,
  setIsOpenPopOver: PropTypes.func.isRequired,
  isOpenPopOver: PropTypes.bool.isRequired
};
function SampleInfosPopUpContainer({ sample, setIsOpenPopOver, isOpenPopOver }) {
  const [labels] = useState(['Kick', 'Snare', 'Hhc', 'Hho', 'Crash', 'Ride', 'Tom']);
  const ref = useRef(null);

  /* Catch any click outside component */
  useEffect(() => {
    const handleClick = async (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        await delay(100);
        setIsOpenPopOver(false);
      }
    };
    document.addEventListener('click', handleClick, true);
    return () => document.removeEventListener('click', handleClick, true);
  });

  const onScroll = () => {
    isOpenPopOver && setIsOpenPopOver(false);
  };

  /* Catch any scroll on page */
  useEffect(() => {
    window.addEventListener('scroll', () => onScroll(), true);
    return () => {
      window.removeEventListener('scroll', () => onScroll(), true);
    };
  });

  const sampleData = useMemo(() => {
    return Object.entries(sample.classification).map(([key, value]) => value);
  }, [sample]);
  const [chartData] = useState({
    labels: labels,
    datasets: [
      {
        data: sampleData,
        backgroundColor: ['rgba(255, 99, 132, 0.2)'],
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
        color: 'white'
      }
    ]
  });

  const options = {
    scales: {
      r: {
        beginAtZero: true,
        min: 0,
        max: 1,
        ticks: {
          stepSize: 1,
          callback: (value) => {
            return '';
          },
          showLabelBackdrop: () => {
            return false;
          },
          angleLines: {
            borderDash: () => {
              return [0];
            }
          }
        },
        grid: {
          color: 'white'
        },
        angleLines: {
          color: 'white'
        },
        pointLabels: {
          color: 'white'
        }
      }
    },
    plugins: {
      legend: {
        display: false
      }
    }
  };

  return (
    <div ref={ref} className="sample-infos-pop-up-main-container">
      <Radar data={chartData} options={options} width={'100%'} height={'100%'} />
    </div>
  );
}

export default SampleInfosPopUpContainer;
