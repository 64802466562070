import PropTypes from 'prop-types';
import filterIcon from '../../assets/filter-logo.svg';

import { CircularProgress } from '@mui/material';
import './ListOfSamplesHeader.css';
import BigRoundedButton from '../Buttons/BigRoundedButton';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import LibrarySelectDropDown from '../LibrarySelectDropDown/LibrarySelectDropDown';
import { memo } from 'react';

ListOfSamplesHeader.propTypes = {
  isMatchingProcessFinished: PropTypes.bool.isRequired,
  setIsOpenFilterDialog: PropTypes.func.isRequired,
  isFetchingAudioBlob: PropTypes.bool.isRequired,
  isOpenSelectLibraryDropDown: PropTypes.bool.isRequired,
  setIsOpenSelectLibraryDropDown: PropTypes.func.isRequired,
  currentSampleLibrary: PropTypes.any.isRequired,
  setCurrentSampleLibrary: PropTypes.func.isRequired,
  sampleLibraries: PropTypes.array.isRequired
};

function ListOfSamplesHeader({
  isMatchingProcessFinished,
  setIsOpenFilterDialog,
  isFetchingAudioBlob,
  isOpenSelectLibraryDropDown,
  setIsOpenSelectLibraryDropDown,
  currentSampleLibrary,
  setCurrentSampleLibrary,
  sampleLibraries
}) {
  return (
    <div className="table-head-border-container">
      <div className="table-head-container">
        {isOpenSelectLibraryDropDown && (
          <LibrarySelectDropDown
            currentSampleLibrary={currentSampleLibrary}
            setCurrentSampleLibrary={setCurrentSampleLibrary}
            setIsOpenLibrarySelectDropDown={setIsOpenSelectLibraryDropDown}
            sampleLibraries={sampleLibraries}
            width="12em"
          />
        )}
        {isMatchingProcessFinished && (
          <>
            <div className="list-of-samples-header-left-part-container">
              <BigRoundedButton
                icon={filterIcon}
                text="FILTERS"
                width="auto"
                padding="1em"
                height="60%"
                onClick={() => setIsOpenFilterDialog((value) => !value)}
                isSvg={true}
                margin="0px"
              />
              {isFetchingAudioBlob && (
                <div
                  style={{
                    width: '10%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                  <CircularProgress
                    style={{
                      color: 'white',
                      width: '20px',
                      height: '20px'
                    }}
                  />
                </div>
              )}
            </div>
            <div className="list-of-samples-header-right-part">
              <BigRoundedButton
                icon={<LibraryMusicIcon />}
                isSvg={false}
                text={currentSampleLibrary.name}
                width="9em"
                height="auto"
                fontSize="14px"
                margin="0px"
                padding="1em"
                backgroundColor="#3F4043"
                fontWeight="200"
                onClick={() => setIsOpenSelectLibraryDropDown((value) => !value)}
                boxShadow={false}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default memo(ListOfSamplesHeader);
