import { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';
import '../DrumPad.css';
import { CircularProgress } from '@mui/material';
import { drumIcons } from '../../../constants/DrumIcons';
import { filtersKeyNameMap } from '../../../constants/FiltersInfos';
import CustomSnackBarError from '../../CustomSnackBarError/CustomSnackBarError';
import AddIcon from '@mui/icons-material/Add';
import CustomToolTip from '../../CustomToolTip/CustomToolTip';
import findRecursivelyFileHandle from '../../../lib/findRecursivelyFileHandler';

import axios from 'axios';
import { ApiRouteSampleBlob } from '../../../constants/ApiRoutes';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

DrumPadMobile.propTypes = {
  index: PropTypes.number,
  drumKitListOfSamplesToPlay: PropTypes.any,
  setDrumKitListOfSamplesToPlay: PropTypes.func,
  keyboardKey: PropTypes.string,
  currentSampleLibrary: PropTypes.any.isRequired,
  volume: PropTypes.number.isRequired
};

function DrumPadMobile({
  index,
  drumKitListOfSamplesToPlay,
  setDrumKitListOfSamplesToPlay,
  keyboardKey,
  currentSampleLibrary,
  volume
}) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [audioSource, setAudioSource] = useState();

  const audioRef = useRef(null);

  // If we change the audio source make sure the audioRef will load it
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.load();
    }
  }, [drumKitListOfSamplesToPlay]);

  // Set the audio source in the audio source state
  useEffect(() => {
    if (drumKitListOfSamplesToPlay[index] && drumKitListOfSamplesToPlay[index].url) {
      setAudioSource(drumKitListOfSamplesToPlay[index].url);
    }
  }, [drumKitListOfSamplesToPlay]);

  // Handle the playing state, used for css
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsPlaying(false);
    }, 60);
    return () => clearTimeout(timer);
  }, [isPlaying]);

  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'filename',
    drop: (item) => addItemToListOfSampleToPlay(item),
    collect: (monitor) => ({
      isOver: !!monitor.isOver()
    })
  }));

  const playAudio = () => {
    setIsPlaying(true);
    audioRef.current.volume = volume;
    audioRef.current.currentTime = 0;
    audioRef.current.play();
  };

  const fetchSampleAndAddItToDrumKit = (item) => {
    axios
      .get(`${API_ENDPOINT}${ApiRouteSampleBlob}`, {
        params: { sample_name: item.filename },
        responseType: 'blob',
        headers: {
          'User-Id': localStorage.getItem('user_id'),
          'Library-Name': currentSampleLibrary.name.toLowerCase()
        }
      })
      .then((response) => {
        const url = URL.createObjectURL(response.data);
        setDrumKitListOfSamplesToPlay((drumKitListOfSamplesToPlay) => ({
          ...drumKitListOfSamplesToPlay,
          [index]: { name: item.filename, tag: item.type, url: url, status: 'success' }
        }));
      })
      .catch(() => {
        setIsError(true);
        setErrorMessage('Error while adding the sample to drumpad. Please try again later.');
        setDrumKitListOfSamplesToPlay([]);
      });
  };

  const getSampleFromUserDirectoryAndAddItToDrumKit = async (item) => {
    const file = await findRecursivelyFileHandle(
      currentSampleLibrary.directoryHandler,
      item.filename
    );
    if (file.length === 0) {
      setIsError(true);
      setErrorMessage('Error while adding the sample to drumpad. Please try again later.');
      setDrumKitListOfSamplesToPlay([]);
      return;
    }
    const realFile = await file[0].getFile();
    const url = URL.createObjectURL(realFile);
    setDrumKitListOfSamplesToPlay((drumKitListOfSamplesToPlay) => ({
      ...drumKitListOfSamplesToPlay,
      [index]: { name: item.filename, tag: item.type, url: url, status: 'success' }
    }));
  };

  const addItemToListOfSampleToPlay = (item) => {
    setDrumKitListOfSamplesToPlay((drumKitListOfSamplesToPlay) => ({
      ...drumKitListOfSamplesToPlay,
      [index]: { name: '', tag: '', url: '', status: 'loading' }
    }));
    currentSampleLibrary.custom
      ? getSampleFromUserDirectoryAndAddItToDrumKit(item)
      : fetchSampleAndAddItToDrumKit(item);
  };

  const getTypeCompleteName = useCallback(() => {
    const completeName = filtersKeyNameMap.find(
      (value) => value.type === drumKitListOfSamplesToPlay[index]?.tag
    );
    return completeName?.name ? completeName.name : null;
  }, [drumKitListOfSamplesToPlay]);

  const getDrumIcon = useCallback(() => {
    return drumIcons.get(drumKitListOfSamplesToPlay[index]?.tag);
  }, [drumKitListOfSamplesToPlay]);

  const printSampleTitle = useCallback(() => {
    return drumKitListOfSamplesToPlay[index]
      ? drumKitListOfSamplesToPlay[index].name
      : 'Drag and drop a sample here';
  }, [drumKitListOfSamplesToPlay]);

  const onClickOnDrumPad = useCallback(() => {
    if (drumKitListOfSamplesToPlay[index]) {
      playAudio();
    }
  }, []);

  return (
    <>
      <CustomSnackBarError
        open={isError}
        severity="error"
        onClose={() => setIsError(false)}
        text={errorMessage}
      />
      <CustomToolTip followCursor={true} enterDelay={1200} title={printSampleTitle()}>
        <div
          ref={drop}
          style={{
            backgroundColor: isOver || isPlaying ? '#28272c' : '#1A191E',
            border: (!drumKitListOfSamplesToPlay[index] && 'dashed 2px') || (isOver && 'solid 2px')
          }}
          className="drum-pad-mobile"
          onClick={onClickOnDrumPad}>
          <audio ref={audioRef} src={audioSource} />
          {drumKitListOfSamplesToPlay[index] ? (
            drumKitListOfSamplesToPlay[index].status !== 'loading' ? (
              <>
                <div className="drum-pad-drum-type-container">
                  <div className="drum-pad-drum-icon-container">
                    <img style={{ width: '80%' }} src={getDrumIcon()} alt="drum-icon" />
                  </div>
                  <div className="drum-pad-drum-tag-container">
                    <div className="drum-pad-drum-type-container">{getTypeCompleteName()}</div>
                  </div>
                </div>
              </>
            ) : (
              <div className="drum-pad-plus-icon-container">
                <CircularProgress style={{ color: 'white' }} />
              </div>
            )
          ) : (
            <div className="drum-pad-plus-icon-container">
              <AddIcon style={{ width: '50%', height: '50%' }} />
            </div>
          )}
          <div className="drum-pad-key-board-container">{keyboardKey}</div>
        </div>
      </CustomToolTip>
    </>
  );
}

export default DrumPadMobile;
