import { useEffect, useState } from 'react';
import DrumKitMobileToolBar from '../Drumkit/Mobile/DrumKitMobileToolBar';
import ListOfSamples from '../ListOfSamples/ListOfSamples';
import './RightSideContainer.css';
import PropTypes from 'prop-types';
import DrumKitMobile from '../Drumkit/Mobile/DrumKitMobile';
import { useMobileOrientation } from 'react-device-detect';
import { isMobile } from 'react-device-detect';

RightSideContainer.propTypes = {
  isMatchingProcessFinished: PropTypes.bool.isRequired,
  totalNumberOfSamples: PropTypes.number.isRequired,
  setTotalNumberOfSamples: PropTypes.func.isRequired,
  loadingMatchingProcess: PropTypes.bool.isRequired,
  samplesLoaded: PropTypes.array,
  setSamplesLoaded: PropTypes.func,
  drumKitListOfSamplesToPlay: PropTypes.any,
  setDrumKitListOfSamplesToPlay: PropTypes.func,
  isFrenchKeyBoard: PropTypes.bool,
  setCurrentBlobPlayed: PropTypes.func,
  currentSampleLibrary: PropTypes.any.isRequired,
  setCurrentSampleLibrary: PropTypes.func.isRequired,
  sampleLibraries: PropTypes.array.isRequired
};

export default function RightSideContainer({
  isMatchingProcessFinished,
  totalNumberOfSamples,
  setTotalNumberOfSamples,
  loadingMatchingProcess,
  samplesLoaded,
  setSamplesLoaded,
  drumKitListOfSamplesToPlay,
  setDrumKitListOfSamplesToPlay,
  isFrenchKeyBoard,
  setCurrentBlobPlayed,
  currentSampleLibrary,
  setCurrentSampleLibrary,
  sampleLibraries
}) {
  const { isLandscape } = useMobileOrientation();
  const [isOpenDrumKitMobile, setIsOpenDrumKitMobile] = useState(isLandscape ? true : false);
  const mobileLandscapeStyle = {
    flexDirection: isMobile && isLandscape && 'row',
    gap: isMobile && isLandscape && '1em'
  };

  useEffect(() => {
    isLandscape ? setIsOpenDrumKitMobile(true) : setIsOpenDrumKitMobile(false);
  }, [isLandscape]);
  return (
    <div className="right-side-container" style={mobileLandscapeStyle}>
      <ListOfSamples
        numberOfItemsPerPage={10}
        isMatchingProcessFinished={isMatchingProcessFinished}
        totalNumberOfSamples={totalNumberOfSamples}
        setTotalNumberOfSamples={setTotalNumberOfSamples}
        loadingMatchingProcess={loadingMatchingProcess}
        setSamplesLoaded={setSamplesLoaded}
        samplesLoaded={samplesLoaded}
        setDrumKitListOfSamplesToPlay={setDrumKitListOfSamplesToPlay}
        isFrenchKeyBoard={isFrenchKeyBoard}
        setCurrentBlobPlayed={setCurrentBlobPlayed}
        currentSampleLibrary={currentSampleLibrary}
        setCurrentSampleLibrary={setCurrentSampleLibrary}
        sampleLibrairies={sampleLibraries}
      />
      {isMobile && !isLandscape && (
        <div className="drumkit-mobile-container">
          {isOpenDrumKitMobile && (
            <DrumKitMobile
              currentSampleLibrary={currentSampleLibrary}
              drumKitListOfSamplesToPlay={drumKitListOfSamplesToPlay}
            />
          )}
          <DrumKitMobileToolBar
            isOpenDrumkit={isOpenDrumKitMobile}
            setIsOpenDrumkit={setIsOpenDrumKitMobile}
            setDrumKitListOfSamplesToPlay={setDrumKitListOfSamplesToPlay}
            drumKitListOfSamplesToPlay={drumKitListOfSamplesToPlay}
            currentSampleLibrary={currentSampleLibrary}
          />
        </div>
      )}
    </div>
  );
}
