import PropTypes from 'prop-types';

SimpleSVGComponent.propTypes = {
  icon: PropTypes.any.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  cursor: PropTypes.bool,
  alt: PropTypes.string.isRequired
};

function SimpleSVGComponent({ icon, width, height, cursor, alt }) {
  return (
    <>
      <img
        src={icon}
        style={{
          width: width ? width : '100%',
          height: height ? height : '100%',
          cursor: cursor ? 'pointer' : 'inherit'
        }}
        alt={alt}
      />
    </>
  );
}

export default SimpleSVGComponent;
