/* eslint-disable no-unused-vars */
import DraggableSampleName from '../ListOfSamples/DraggableSampleName';
import PropTypes from 'prop-types';

import './SampleCellFromListOfSamples.css';
import { useDrag } from 'react-dnd';
import RoundedLogoButton from '../Buttons/RoundedLogoButton';
import PlayIconSVG from '../../assets/play-icon.svg';
import DownloadIconsSVG from '../../assets/download-icon.svg';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import SampleTypeButton from '../Buttons/SampleTypeButton';
import SampleInfosPopUpContainer from './SampleInfosPopUpContainer';
import { Popover } from '@mui/material';
import CosineSimilarityFromSample from './CosineSimilarityFromSample';

SampleCellFromListOfSamples.propTypes = {
  sample: PropTypes.any,
  index: PropTypes.number,
  indexOfTrackPlaying: PropTypes.number,
  handleClickOnDownloadFromListOfSample: PropTypes.func,
  handleClickOnSampleFromListOfSample: PropTypes.func,
  isFetchingAudioBlob: PropTypes.bool,
  currentSampleLibrary: PropTypes.any.isRequired,
  paginationSwitch: PropTypes.bool.isRequired,
  currentPageSelected: PropTypes.number.isRequired
};

function SampleCellFromListOfSamples({
  sample,
  index,
  indexOfTrackPlaying,
  handleClickOnDownloadFromListOfSample,
  handleClickOnSampleFromListOfSample,
  isFetchingAudioBlob,
  currentSampleLibrary,
  paginationSwitch,
  currentPageSelected
}) {
  const popOverRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpenPopOver, setIsOpenPopOver] = useState(false);

  const [{ opacity }, drag] = useDrag(
    () => ({
      type: 'filename',
      item: { filename: sample.filename, type: sample.tag },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.2 : 1
      })
    }),
    [sample.filename]
  );

  const id = isOpenPopOver ? 'simple-popover' : undefined;

  const queryParams = new URLSearchParams(window.location.search);
  const keyParam = queryParams.get('key');

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
    setIsOpenPopOver(false);
  }, []);

  const onClickOnSampleTypeButton = useCallback((event) => {
    setAnchorEl(popOverRef.current);
    setIsOpenPopOver(true);
  }, []);

  const realSampleIndex = useMemo(() => {
    return paginationSwitch ? (currentPageSelected - 1) * 10 + index : index;
  }, [paginationSwitch, index]);

  return (
    <>
      <div ref={drag} className="sample-name-and-buttons-container">
        <Popover
          sx={{
            pointerEvents: 'none',
            '& .css-3bmhjh-MuiPaper-root-MuiPopover-paper': {
              borderRadius: '10px',
              backgroundColor: 'inherit'
            }
          }}
          className="popover"
          open={isOpenPopOver}
          id={id}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          onClose={() => handlePopoverClose()}>
          <SampleInfosPopUpContainer
            setIsOpenPopOver={setIsOpenPopOver}
            isOpenPopOver={isOpenPopOver}
            sample={sample}
          />
        </Popover>
        <div className="play-and-download-icon-container">
          <RoundedLogoButton
            icon={PlayIconSVG}
            onClick={() => handleClickOnSampleFromListOfSample(index)}
            logoWidth={'20%'}
            title="Play sample"
          />
          <RoundedLogoButton
            icon={DownloadIconsSVG}
            onClick={
              currentSampleLibrary.download ||
              (keyParam == process.env.REACT_APP_GOD_MODE && keyParam)
                ? () => handleClickOnDownloadFromListOfSample(sample.filename)
                : () => null
            }
            logoWidth={'60%'}
            disabled={
              !currentSampleLibrary.download ||
              (keyParam == process.env.REACT_APP_GOD_MODE && keyParam)
            }
            title={
              currentSampleLibrary.download ||
              (keyParam == process.env.REACT_APP_GOD_MODE && keyParam)
                ? 'Download sample'
                : 'Cannot download sample, this library is only for demonstration purposes'
            }
          />
        </div>
        <DraggableSampleName
          sampleName={sample.filename}
          sampleType={sample.tag}
          index={realSampleIndex}
          isSelected={indexOfTrackPlaying === index ? true : false}
        />
      </div>
      <div className="drum-icon-container" ref={popOverRef}>
        <div className="left-border"></div>
        <SampleTypeButton onClick={onClickOnSampleTypeButton} type={sample.tag} />
      </div>
      <div className="sample-similarity-container">
        <div className="left-border"></div>
        <CosineSimilarityFromSample sampleCosineSimilarity={sample.similarity_score} />
      </div>
    </>
  );
}

export default memo(SampleCellFromListOfSamples);
