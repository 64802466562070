/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { useEffect } from 'react';
import { useKeyPress } from '../../hooks/UseKeyPress';
import { motion } from 'framer-motion';

import PropTypes from 'prop-types';

import { DrumKitKeyboardKeys } from '../../constants/DrumKitKeyboard';
import { mapKeysToIndex } from '../../constants/MapKeysToIndex';

import { DrumKitKeyboardQWERTYLabels } from '../../constants/DrumKitKeyboard';
import { mapKeysToIndexQWERTYLabels } from '../../constants/MapKeysToIndex';

import { Slider, Tooltip, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

import DrumPad from './DrumPad';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import './DrumKit.css';
import RoundedLogoButton from '../Buttons/RoundedLogoButton';
import DeleteIconSVG from '../../assets/delete-icon.svg';
import DownloadIconSVG from '../../assets/download-icon.svg';
import AutoPopulateIconSVG from '../../assets/auto-populate-icon.svg';
import CustomSnackBarError from '../CustomSnackBarError/CustomSnackBarError';
import RoundedTextInfo from '../TextContainer/RoundedTextInfo';
import LoadingGIFWithText from '../LoadingGIF/LoadingGIFWithText';
import findRecursivelyFileHandle from '../../lib/findRecursivelyFileHandler';

import axios from 'axios';
import {
  ApiRouteSampleBlob,
  ApiRouteDrumkitSamplesDownload,
  ApiRouteDrumkitSamplesInformation
} from '../../constants/ApiRoutes';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.primary,
    color: 'white',
    boxShadow: theme.shadows[1],
    fontSize: 15
  }
}));

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

DrumKit.propTypes = {
  drumKitListOfSamplesToPlay: PropTypes.any,
  setDrumKitListOfSamplesToPlay: PropTypes.func,
  reference: PropTypes.any,
  isFrenchKeyBoard: PropTypes.bool,
  setIsFrenchKeyBoard: PropTypes.func,
  isMatchingProcessFinished: PropTypes.bool,
  shouldDoAutomaticAutoPopulateDrumkit: PropTypes.bool.isRequired,
  setShouldDoAutomaticAutoPopulateDrumkit: PropTypes.func.isRequired,
  currentSampleLibrary: PropTypes.any.isRequired
};

function DrumKit({
  drumKitListOfSamplesToPlay,
  setDrumKitListOfSamplesToPlay,
  reference,
  isFrenchKeyBoard,
  setIsFrenchKeyBoard,
  isMatchingProcessFinished,
  shouldDoAutomaticAutoPopulateDrumkit,
  setShouldDoAutomaticAutoPopulateDrumkit,
  currentSampleLibrary
}) {
  /* Boolean to know if any track on the drum kit is played */
  const [isATrackPlaying, setIsATrackPlaying] = useState(false);

  const [currentKeyboardKeys, setCurrentKeyboardKeys] = useState(DrumKitKeyboardQWERTYLabels);
  const [currentMapKeysToIndex, setCurrentMapKeysToIndex] = useState(mapKeysToIndexQWERTYLabels);

  /* Boolean to know if the auto populate is in loading */
  const [isLoadingAutoPopulate, setIsLoadingAutoPopulate] = useState(false);

  /* Boolean to activate the alert telling that list of samples is empty when we want to download drumkit */
  const [triggerListOfSamplesEmty, setTriggerListOfSamplesEmpty] = useState(false);

  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [isSuccessAutoPopulate, setIsScucessAutoPopulate] = useState(false);

  const [drumPadsVolume, setDrumPadsVolume] = useState(0.3);

  const queryParams = new URLSearchParams(window.location.search);
  const keyParam = queryParams.get('key');

  /* A timer mostly to handle back ground color of a drumpad when playing a track */
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsATrackPlaying(false);
    }, 60);
    return () => clearTimeout(timer);
  }, [isATrackPlaying]);

  useEffect(() => {
    if (isFrenchKeyBoard) {
      setCurrentMapKeysToIndex(mapKeysToIndex);
      setCurrentKeyboardKeys(DrumKitKeyboardKeys);
    } else {
      setCurrentMapKeysToIndex(mapKeysToIndexQWERTYLabels);
      setCurrentKeyboardKeys(DrumKitKeyboardQWERTYLabels);
    }
  }, [isFrenchKeyBoard]);

  useEffect(() => {
    if (shouldDoAutomaticAutoPopulateDrumkit) {
      onClickAutoPopulateButton();
      setShouldDoAutomaticAutoPopulateDrumkit(false);
    }
  }, [shouldDoAutomaticAutoPopulateDrumkit]);

  const playAudioBuffer = (url) => {
    if (url) {
      const audio = new Audio(url);
      audio.volume = drumPadsVolume;
      audio.play(url);
    }
  };

  const onChangeDrumPadsVolume = (event) => {
    setDrumPadsVolume(event.target.value / 100);
  };

  const fetchAllBlobsFromAutoPopulateRequest = (data) => {
    const urls = data.map((elem) =>
      axios.get(`${API_ENDPOINT}${ApiRouteSampleBlob}`, {
        params: { sample_name: elem.name },
        responseType: 'blob',
        headers: {
          'User-Id': localStorage.getItem('user_id'),
          'Library-Name': currentSampleLibrary.name.toLowerCase()
        }
      })
    );
    axios
      .all(urls)
      .then(
        axios.spread((...responses) => {
          responses.map((response, index) => {
            const url = URL.createObjectURL(response.data);
            setDrumKitListOfSamplesToPlay((drumKitListOfSamplesToPlay) => ({
              ...drumKitListOfSamplesToPlay,
              [index]: {
                name: drumKitListOfSamplesToPlay[index].name,
                tag: drumKitListOfSamplesToPlay[index].tag,
                url: url,
                status: 'success',
                blob: response.data
              }
            }));
          });
          setIsLoadingAutoPopulate(false);
          setIsScucessAutoPopulate(true);
        })
      )
      .catch(() => {
        setIsError(true);
        setIsLoadingAutoPopulate(false);
        setErrorMessage(
          'Error while getting samples from autopopulate option. Please try again later'
        );
      });
  };

  const getBlobFromUserDirectoryFromAutoPopulateRequest = (data) => {
    const results = data.map((elem) =>
      findRecursivelyFileHandle(currentSampleLibrary.directoryHandler, elem.name)
    );
    Promise.all(results)
      .then(async (fileHandlers) => {
        const files = fileHandlers.map((elem) => elem[0].getFile());
        Promise.all(files).then((filesResolved) => {
          filesResolved.map((file, index) => {
            const url = URL.createObjectURL(file);
            const blob = new Blob([file], { type: 'audio/wav' });
            setDrumKitListOfSamplesToPlay((drumKitListOfSamplesToPlay) => ({
              ...drumKitListOfSamplesToPlay,
              [index]: {
                name: drumKitListOfSamplesToPlay[index].name,
                tag: drumKitListOfSamplesToPlay[index].tag,
                url: url,
                status: 'success',
                blob: blob
              }
            }));
            setIsLoadingAutoPopulate(false);
            setIsScucessAutoPopulate(true);
          });
        });
      })
      .catch(() => {
        setIsError(true);
        setIsLoadingAutoPopulate(false);
        setErrorMessage(
          'It seems that Sample Match cannot access your local sample library. Try to upload again your json file and verify the location of your sample library.'
        );
      });
  };

  const onClickAutoPopulateButton = () => {
    setIsLoadingAutoPopulate(true);
    axios
      .get(`${API_ENDPOINT}${ApiRouteDrumkitSamplesInformation}`, {
        withCredentials: true,
        headers: {
          'User-Id': localStorage.getItem('user_id'),
          'Library-Name': currentSampleLibrary.name.toLowerCase()
        }
      })
      .then((res) => {
        currentSampleLibrary.custom
          ? getBlobFromUserDirectoryFromAutoPopulateRequest(res.data)
          : fetchAllBlobsFromAutoPopulateRequest(res.data);
        setDrumKitListOfSamplesToPlay(res.data);
      })
      .catch(() => {
        setIsLoadingAutoPopulate(false);
        setIsError(true);
        setErrorMessage('Error while auto populating the drumkit. Please try again later');
      });
  };

  const onClickDownloadDrumKitSamples = () => {
    if (drumKitListOfSamplesToPlay.length === 0) setTriggerListOfSamplesEmpty(true);
    else {
      const tmpKeys = Object.keys(drumKitListOfSamplesToPlay);
      const tmpList = tmpKeys.map((value) => drumKitListOfSamplesToPlay[value].name).toString();
      axios
        .get(`${API_ENDPOINT}${ApiRouteDrumkitSamplesDownload}`, {
          params: { filenames: tmpList },
          withCredentials: true,
          responseType: 'blob',
          headers: {
            'User-Id': localStorage.getItem('user_id'),
            'Library-Name': currentSampleLibrary.name.toLowerCase()
          }
        })
        .then((res) => {
          const url = URL.createObjectURL(res.data);
          let a = document.createElement('a');
          a.href = url;
          a.download = 'samples-match-drumkit';
          a.click();
          URL.revokeObjectURL(url);
        })
        .catch(() => {
          setIsError(true);
          setErrorMessage('Error while downloading sample. Please try again later');
        });
    }
  };

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  return (
    <>
      <CustomSnackBarError
        open={triggerListOfSamplesEmty}
        onClose={() => setTriggerListOfSamplesEmpty(false)}
        severity="warning"
        text="Your drumkit is empty !"
      />
      <CustomSnackBarError
        open={isError}
        onClose={() => setIsError(false)}
        severity="error"
        text={errorMessage}
      />
      <CustomSnackBarError
        open={isSuccessAutoPopulate}
        onClose={() => setIsScucessAutoPopulate(false)}
        severity="success"
        text="Successfully autopulated drumkit"
      />
      <motion.div
        animate={{ opacity: 1, scale: 1 }}
        initial={{ opacity: 0, scale: 0.8 }}
        transition={{ delay: 2, duration: 1, ease: [0, 0.71, 0.2, 1.01] }}
        className="drum-kit-container">
        {isMatchingProcessFinished && (
          <div className="drum-kit-border-container">
            {!isLoadingAutoPopulate ? (
              <>
                <div className="drum-kit-select-container"></div>
                <div className="drum-kit-pads-container">
                  <motion.div
                    animate="show"
                    initial="hidden"
                    variants={container}
                    className="drum-kit-first-line">
                    {currentKeyboardKeys
                      .filter((value, index) => index <= 3)
                      .map((value, index) => {
                        return (
                          <DrumPad
                            key={index}
                            index={index}
                            drumKitListOfSamplesToPlay={drumKitListOfSamplesToPlay}
                            setDrumKitListOfSamplesToPlay={setDrumKitListOfSamplesToPlay}
                            keyboardKey={value}
                            currentSampleLibrary={currentSampleLibrary}
                            volume={drumPadsVolume}
                            currentKeyboardKeys={currentKeyboardKeys}
                            currentMapKeysToIndex={currentMapKeysToIndex}
                          />
                        );
                      })}
                  </motion.div>
                  <motion.div
                    animate="show"
                    initial="hidden"
                    variants={container}
                    className="drum-kit-second-line">
                    {currentKeyboardKeys
                      .filter((value, index) => index > 3)
                      .map((value, index) => {
                        return (
                          <DrumPad
                            key={index}
                            index={index + 4}
                            drumKitListOfSamplesToPlay={drumKitListOfSamplesToPlay}
                            setDrumKitListOfSamplesToPlay={setDrumKitListOfSamplesToPlay}
                            keyboardKey={value}
                            currentSampleLibrary={currentSampleLibrary}
                            volume={drumPadsVolume}
                            currentKeyboardKeys={currentKeyboardKeys}
                            currentMapKeysToIndex={currentMapKeysToIndex}
                          />
                        );
                      })}
                  </motion.div>
                </div>
                <div className="drum-kit-actions-container">
                  <div className="drum-kit-actions-left-side-container">
                    <div className="durm-kit-actions-left-side-first-line-container">
                      <div
                        style={{
                          width: '30%',
                          color: 'white',
                          fontSize: '10px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}>
                        <KeyboardIcon />
                      </div>
                      <div style={{ display: 'flex' }}>
                        <div
                          style={{
                            color: isFrenchKeyBoard ? '#625f6d' : 'white',
                            cursor: 'pointer'
                          }}
                          onClick={() => setIsFrenchKeyBoard(false)}>
                          EN
                        </div>
                        <span style={{ color: 'white' }}>&nbsp;&nbsp;|&nbsp;&nbsp; </span>
                        <div
                          style={{
                            color: isFrenchKeyBoard ? 'white' : '#625f6d',
                            cursor: 'pointer'
                          }}
                          onClick={() => setIsFrenchKeyBoard(true)}>
                          FR
                        </div>
                      </div>
                    </div>
                    <div className="drum-kit-actions-left-side-second-line-container">
                      <div
                        style={{
                          width: '30%',
                          color: 'white',
                          fontSize: '10px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}>
                        <VolumeUpIcon />
                      </div>
                      <div className="drumkit-volume-container">
                        {/* <Slider
                          aria-label="Volume"
                          value={drumPadsVolume * 100}
                          onChange={onChangeDrumPadsVolume}
                          sx={{
                            color: 'white',
                            '& .css-14pt78w-MuiSlider-rail': {
                              height: '1px'
                            },
                            '& .css-1gv0vcd-MuiSlider-track': {
                              height: '1px'
                            },
                            '& .css-1j2lva8-MuiSlider-thumb': {
                              height: '14px',
                              width: '14px'
                            }
                          }}
                        /> */}
                        <input
                          type="range"
                          // ref={volumeBar}
                          defaultValue={drumPadsVolume * 100}
                          onChange={onChangeDrumPadsVolume}
                          className="audio-progress-bar"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="drum-kit-actions-right-side-container">
                    <RoundedLogoButton
                      icon={AutoPopulateIconSVG}
                      onClick={onClickAutoPopulateButton}
                      title="Autopopulate drumkit"
                    />
                    <RoundedLogoButton
                      icon={DeleteIconSVG}
                      onClick={() => setDrumKitListOfSamplesToPlay([])}
                      title="Clear drumkit"
                    />
                    <RoundedLogoButton
                      icon={DownloadIconSVG}
                      onClick={
                        currentSampleLibrary.download ||
                        (keyParam == process.env.REACT_APP_GOD_MODE && keyParam)
                          ? onClickDownloadDrumKitSamples
                          : null
                      }
                      title={
                        currentSampleLibrary.download ||
                        (keyParam == process.env.REACT_APP_GOD_MODE && keyParam)
                          ? 'Download drumkit'
                          : 'Cannot download drumkit, this sample library is only used for demonstration purposes'
                      }
                      disabled={
                        !(
                          currentSampleLibrary.download ||
                          (keyParam == process.env.REACT_APP_GOD_MODE && keyParam)
                        )
                      }
                    />
                  </div>
                </div>
              </>
            ) : (
              <div className="drum-kit-loading-autopopulate-container">
                <LoadingGIFWithText width="auto" height="100%" text="Autopopulating drumkit ..." />
              </div>
            )}
          </div>
        )}
      </motion.div>
    </>
  );
}

export default DrumKit;
