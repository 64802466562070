import { useEffect, useReducer, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Header from './components/Header/Header';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import './App.css';
import LeftContainer from './components/LeftContainer/LeftContainer';
import UploadMainPage from './components/UploadComponents/UploadMainPage';
import RightSideContainer from './components/RightSideContainer/RightSideContainer';
import Footer from './components/Footer/Footer';
import { findDefaultCurrentLibrary } from './constants/SampleLibraries';
import CustomSnackBarError from './components/CustomSnackBarError/CustomSnackBarError';
import { trackUploadedIntialState, trackUploadedReducer } from './reducers/trackUploadedReducer';
import CustomContext from './contexts/customContext';

import axios from 'axios';
import { ApiRouteLibrariesInformation, ApiRouteAudiofileProcess } from './constants/ApiRoutes';
import LegalDialog, { CheckLegal } from './components/legaldialog/legalDialog';
import { isMobile, useMobileOrientation } from 'react-device-detect';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function App() {
  /* Boolean to know if the samples have been fetched from the server */
  const [isMatchingProcessFinished, setIsMatchingProcessFinished] = useState(false);

  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [isLoadingUploadingFile, setIsLoadingUploadingFile] = useState(false);

  /* The total number of samples processed by the server according to the filters given */
  const [totalNumberOfSamples, setTotalNumberOfSamples] = useState(0);

  /* Boolean to know if the matching process is loading */
  const [loadingMatchingProcess, setLoadingMatchingProcess] = useState(false);
  const [samplesLoaded, setSamplesLoaded] = useState([]);

  /* A list of sample corresponding to drumkit. Each index of the list is representing a pad on the drumkit */
  const [drumKitListOfSamplesToPlay, setDrumKitListOfSamplesToPlay] = useState([]);

  /* Boolean to know if we are currently using a AZERTY or QWERTY keyboard */
  const [isFrenchKeyBoard, setIsFrenchKeyBoard] = useState(false);

  /* The current blob from the list of samples that is played */
  const [currentBlobPlayed, setCurrentBlobPlayed] = useState();

  /* A boolean to know if we should automaticaly auto populate drumkit, basically to know if the user juste dropped a new file */
  const [shouldDoAutomaticAutoPopulateDrumkit, setShouldDoAutomaticAutoPopulateDrumkit] =
    useState(false);

  const [errorMessage, setErrorMessage] = useState();
  const [isOpenErrorSnackBar, setIsOpenErrorSnackBar] = useState(false);

  /* The current library of samples selected by the user */
  const [currentSampleLibrary, setCurrentSampleLibrary] = useState();
  const [isCurrentSampleLibrarySet, setIsCurrentSampleLibrarySet] = useState(false);

  /* The sample libraries available for the user */
  const [sampleLibraries, setSampleLibraries] = useState([]);

  /* Is loading getting sample library */
  const [isLoadingSampleLibrary, setIsLoadingSampleLibrary] = useState(false);

  /* Is error when trying to get sample library info */
  const [isErrorOnSampleLibrary, setIsErroOnSampleLibrary] = useState(false);

  const [trackUploadedState, trackUploadedDispatcher] = useReducer(
    trackUploadedReducer,
    trackUploadedIntialState
  );

  const theme = createTheme({
    palette: {
      primary: {
        main: '#3B3650'
      },
      secondary: {
        main: '#3F4043'
      },
      black: {
        main: '#1A191E'
      },
      darkgrey: {
        main: '#28272c'
      },
      lightgrey: {
        main: '#3F4043'
      },
      red: {
        main: '#AA4686'
      }
    }
  });

  /* Call the process function once the file has been uploaded or the sample library has changed */
  useEffect(() => {
    if (currentSampleLibrary) {
      setIsCurrentSampleLibrarySet(true);
    }
    if (isFileUploaded) {
      if (isCurrentSampleLibrarySet) {
        dispatchProcessFile();
      } else {
        setIsFileUploaded(false);
      }
    }
  }, [isFileUploaded, currentSampleLibrary]);

  /* Call the get libraries sample infos to have the information and details of the current sample libraries of samples that are present on the server */
  useEffect(() => {
    fetchSampleLibrariesInfos();
  }, []);

  const fetchSampleLibrariesInfos = () => {
    const id = localStorage.getItem('user_id');
    const headers = id ? { 'User-Id': id } : {};

    setIsLoadingSampleLibrary(true);
    axios
      .get(`${API_ENDPOINT}${ApiRouteLibrariesInformation}`, {
        headers: headers
      })
      .then((res) => {
        setSampleLibraries(res.data);
        setCurrentSampleLibrary(findDefaultCurrentLibrary(res.data));
        setIsLoadingSampleLibrary(false);
        localStorage.setItem('user_id', res.headers['user-id']);
      })
      .catch(() => {
        setErrorMessage(
          'Error while getting sample libraries informations, please try again later'
        );
        setIsOpenErrorSnackBar(true);
        setIsLoadingSampleLibrary(false);
        setIsErroOnSampleLibrary(true);
      });
  };

  const { isLandscape } = useMobileOrientation();

  const dispatchProcessFile = () => {
    setLoadingMatchingProcess(true);
    setIsMatchingProcessFinished(false);
    axios
      .get(`${API_ENDPOINT}${ApiRouteAudiofileProcess}`, {
        headers: {
          'User-Id': localStorage.getItem('user_id'),
          'Library-Name': currentSampleLibrary.name.toLowerCase(),
          'Library-Type': currentSampleLibrary.custom
        }
      })
      .then((res) => {
        setLoadingMatchingProcess(false);
        setTotalNumberOfSamples(res.data.samples_number);
        setIsMatchingProcessFinished(true);
        setShouldDoAutomaticAutoPopulateDrumkit(true);
        localStorage.setItem('initial_total_number_of_samples', res.data.samples_number);
      })
      .catch(() => {
        setLoadingMatchingProcess(false);
        setErrorMessage('Error while processing your file. Please try again later');
        setIsOpenErrorSnackBar(true);
        setIsFileUploaded(false);
      });
  };

  const providerInitialState = {
    trackUploadedState,
    trackUploadedDispatcher,
    fetchSampleLibrariesInfos,
    setSampleLibraries
  };

  const [agreed, setAgreed] = useState(false);

  useEffect(() => {
    setAgreed(CheckLegal());
  }, [agreed]);

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <DndProvider backend={HTML5Backend}>
          <CustomContext.Provider value={providerInitialState}>
            <CustomSnackBarError
              open={isOpenErrorSnackBar}
              text={errorMessage}
              severity="error"
              onClose={() => setIsOpenErrorSnackBar(false)}
            />
            <Header
              setIsFileUploaded={setIsFileUploaded}
              isFileUploaded={isFileUploaded}
              setIsLoadingUploadingFile={setIsLoadingUploadingFile}
              setIsMatchingProcessFinished={setIsMatchingProcessFinished}
            />
            <div
              className="middle-container"
              style={{ height: isMobile && isLandscape && isFileUploaded && '75%' }}>
              {!agreed ? (
                <LegalDialog onChange={() => setAgreed(CheckLegal())} />
              ) : (
                <>
                  {isFileUploaded && isCurrentSampleLibrarySet ? (
                    <>
                      <LeftContainer
                        drumKitListOfSamplesToPlay={drumKitListOfSamplesToPlay}
                        setDrumKitListOfSamplesToPlay={setDrumKitListOfSamplesToPlay}
                        isFrenchKeyBoard={isFrenchKeyBoard}
                        setIsFrenchKeyBoard={setIsFrenchKeyBoard}
                        currentBlobPlayed={currentBlobPlayed}
                        isMatchingProcessFinished={isMatchingProcessFinished}
                        shouldDoAutomaticAutoPopulateDrumkit={shouldDoAutomaticAutoPopulateDrumkit}
                        setShouldDoAutomaticAutoPopulateDrumkit={
                          setShouldDoAutomaticAutoPopulateDrumkit
                        }
                        currentSampleLibrary={currentSampleLibrary}
                      />
                      <RightSideContainer
                        isMatchingProcessFinished={isMatchingProcessFinished}
                        totalNumberOfSamples={totalNumberOfSamples}
                        setTotalNumberOfSamples={setTotalNumberOfSamples}
                        loadingMatchingProcess={loadingMatchingProcess}
                        setSamplesLoaded={setSamplesLoaded}
                        samplesLoaded={samplesLoaded}
                        drumKitListOfSamplesToPlay={drumKitListOfSamplesToPlay}
                        setDrumKitListOfSamplesToPlay={setDrumKitListOfSamplesToPlay}
                        isFrenchKeyBoard={isFrenchKeyBoard}
                        setCurrentBlobPlayed={setCurrentBlobPlayed}
                        currentSampleLibrary={currentSampleLibrary}
                        setCurrentSampleLibrary={setCurrentSampleLibrary}
                        sampleLibraries={sampleLibraries}
                      />
                    </>
                  ) : (
                    <>
                      <UploadMainPage
                        isFileUploaded={isFileUploaded}
                        setIsFileUploaded={setIsFileUploaded}
                        setIsLoadingUploadingFile={setIsLoadingUploadingFile}
                        isLoadingUploadingFile={isLoadingUploadingFile}
                        currentSampleLibrary={currentSampleLibrary}
                        setCurrentSampleLibrary={setCurrentSampleLibrary}
                        sampleLibraries={sampleLibraries}
                        isLoadingSampleLibrary={isLoadingSampleLibrary}
                        isErrorOnSampleLibrary={isErrorOnSampleLibrary}
                      />
                    </>
                  )}
                </>
              )}
            </div>
            <Footer></Footer>
          </CustomContext.Provider>
        </DndProvider>
      </div>
    </ThemeProvider>
  );
}

export default App;
