import './LibrarySelectDropDownItem.css';
import PropTypes from 'prop-types';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import { motion } from 'framer-motion';

LibrarySelectDropDownItem.propTypes = {
  library: PropTypes.any.isRequired,
  onSelectItem: PropTypes.func.isRequired
};

export default function LibrarySelectDropDownItem({ library, onSelectItem }) {
  const item = {
    hidden: { opacity: 0 },
    show: { opacity: 1 }
  };
  return (
    <div className="library-select-drop-down-item-main-container">
      <motion.div
        whileHover={{ scale: 1.02 }}
        onClick={() => onSelectItem(library)}
        className="library-select-drop-down-item-border-container"
        variants={item}>
        <div className="library-select-drop-down-icon-and-name-container">
          <div
            style={{
              width: '30%',
              display: 'flex',
              justifyContent: 'flex-start'
            }}>
            <LibraryMusicIcon />
          </div>
          <div
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              width: '60%'
            }}>
            {library?.name}
          </div>
        </div>
      </motion.div>
    </div>
  );
}
