import './Footer.css';
// import twitterLogoSVG from '../../assets/twitter-logo.svg';
// import youtubeLogoSVG from '../../assets/youtube-logo.svg';

function Footer() {
  return (
    <div className="footer-container">
      <div className="credits-container">
        <a
          href="https://sites.google.com/view/samplematch?pli=1"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            fontSize: '13px',
            fontWeight: 200,
            color: '#FFFFFF'
          }}>
          © Sony Computer Science Laboratories, Inc.
        </a>
      </div>
      <div className="sony-footer">
        {' '}
        <a
          href="https://campaign.odw.sony-europe.com/dynamic/terms-conditions/index.jsp?country=fr&language=fr"
          target="_blank"
          rel="noreferrer">
          TERMS AND CONDITIONS OF WEBSITE USE
        </a>
        <a
          href="https://www.sony.fr/eu/pages/privacy/fr_FR/privacy_overview.html"
          target="_blank"
          rel="noreferrer">
          PRIVACY POLICY
        </a>
      </div>
    </div>
  );
}

// https://services.sony.fr/supportmvc/fr/contact/

export default Footer;
