import axios from 'axios';
import './UploadMainPage.css';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { motion } from 'framer-motion';

import uploadButtonHoverSVG from '../../assets/upload-button-hover.svg';
import uploadButtonNormalSVG from '../../assets/upload-button-normal.svg';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';

import CustomSnackBarError from '../CustomSnackBarError/CustomSnackBarError';
import BigRoundedButton from '../Buttons/BigRoundedButton';
import LibrarySelectDropDown from '../LibrarySelectDropDown/LibrarySelectDropDown';
import { useCustomContext } from '../../contexts/customContext';
import LoadingGIFWithText from '../LoadingGIF/LoadingGIFWithText';
import RoundedTextInfo from '../TextContainer/RoundedTextInfo';
import { isMobileOnly } from 'react-device-detect';

UploadMainPage.propTypes = {
  isFileUploaded: PropTypes.bool.isRequired,
  setIsFileUploaded: PropTypes.func.isRequired,
  setIsLoadingUploadingFile: PropTypes.func.isRequired,
  isLoadingUploadingFile: PropTypes.bool.isRequired,
  currentSampleLibrary: PropTypes.any,
  setCurrentSampleLibrary: PropTypes.func.isRequired,
  sampleLibraries: PropTypes.array,
  isLoadingSampleLibrary: PropTypes.bool.isRequired,
  isErrorOnSampleLibrary: PropTypes.bool.isRequired
};

import { ApiRouteAudiofile } from '../../constants/ApiRoutes';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function UploadMainPage({
  isFileUploaded,
  setIsFileUploaded,
  setIsLoadingUploadingFile,
  isLoadingUploadingFile,
  currentSampleLibrary,
  setCurrentSampleLibrary,
  sampleLibraries,
  isLoadingSampleLibrary,
  isErrorOnSampleLibrary
}) {
  /* The status message in case of error */
  const [errorMessage, setErrorMessage] = useState('');

  /* Boolean to know when to open the uploaded file success snackbar */
  const [openSuccessFileUpload, setOpenSuccesFileUpload] = useState(false);

  const [openErrorSnackBar, setOpenErrorSnackBar] = useState(false);

  const [isAiffFile, setIsAiffFile] = useState(false);

  const [isHoverUploadButton, setIsHoverUploadButton] = useState(false);

  const [isOpenSelectLibraryDropDown, setIsOpenSelectLibraryDropDown] = useState(false);

  const [uploadingFileLoadingMessage, setUploadingFileLoadingMessage] =
    useState('Uploading your file ...');

  const { trackUploadedDispatcher } = useCustomContext();

  useEffect(() => {
    if (isLoadingUploadingFile) {
      setTimeout(() => {
        setUploadingFileLoadingMessage('Almost there ...');
      }, [5000]);
    }
  }, [isLoadingUploadingFile]);

  useEffect(() => {
    if (!isFileUploaded && openSuccessFileUpload) {
      setOpenSuccesFileUpload(false);
      setErrorMessage('Select a library first, please');
      setOpenErrorSnackBar(true);
    }
  }, [isFileUploaded]);

  const updateUploadedFile = (newFile) => {
    if (checkFileDroppedBeforeSendToServer(newFile[0]) === 'error') return;
    setIsFileUploaded(false);
    setIsLoadingUploadingFile(true);
    trackUploadedDispatcher({ type: 'UPDATE_NAME', name: newFile[0].name });
    trackUploadedDispatcher({ type: 'UPDATE_URL', url: URL.createObjectURL(newFile[0]) });
    const data = new FormData();
    data.append('file', newFile[0]);
    axios
      .post(`${API_ENDPOINT}${ApiRouteAudiofile}`, data, {
        responseType: 'blob',
        headers: {
          'User-Id': localStorage.getItem('user_id')
        }
      })
      .then(() => {
        setOpenSuccesFileUpload(true);
        setIsLoadingUploadingFile(false);
        setIsFileUploaded(true);
      })
      .catch(() => {
        setErrorMessage('Error while uploading your file. Please try again later.');
        setIsLoadingUploadingFile(false);
        setOpenErrorSnackBar(true);
      });
  };

  const checkFileDroppedBeforeSendToServer = useCallback((file) => {
    if (file.size < 50000) {
      setErrorMessage('Audio file too short');
      setOpenErrorSnackBar(true);
      return 'error';
    }
    if (file.type === 'audio/x-aiff' || file.type === 'audio/aiff') setIsAiffFile(true);
    return null;
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'audio/mpeg': ['.mp3'],
      'audio/x-wav': ['.wav'],
      'audio/x-aiff': ['.aif', '.aiff', '.aifc']
    },
    onDrop: updateUploadedFile,
    maxFiles: 1,
    noKeyboard: true
  });

  const handleCloseSnackBarUploadFile = (event, reason) => {
    if (reason === 'clickaway') return;
    setOpenSuccesFileUpload(false);
  };
  return (
    <div className="upload-main-page-main-container">
      <CustomSnackBarError
        open={isAiffFile}
        onClose={() => setIsAiffFile(false)}
        severity="warning"
        text="Some browser cannot play AIFF files"
      />
      <CustomSnackBarError
        open={openSuccessFileUpload}
        onClose={handleCloseSnackBarUploadFile}
        severity="success"
        text="File uploaded successfully"
      />
      <CustomSnackBarError
        open={openErrorSnackBar}
        onClose={() => setOpenErrorSnackBar(false)}
        severity="error"
        text={errorMessage}
      />
      <div className="upload-main-page-border-main-container">
        <div className="upload-main-page-dashed-border-main-container">
          {isErrorOnSampleLibrary ? (
            <>
              <RoundedTextInfo text="Error while getting samples libraries ... Try to refresh the page or come back later" />
            </>
          ) : (
            <>
              {isLoadingUploadingFile || isLoadingSampleLibrary ? (
                <div className="loading-gif-container">
                  <LoadingGIFWithText
                    width="auto"
                    height="100%"
                    text={
                      isLoadingUploadingFile
                        ? uploadingFileLoadingMessage
                        : 'Getting sample libraries information ...'
                    }
                  />
                </div>
              ) : (
                <>
                  {!isMobileOnly ? (
                    <>
                      <div {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                        <motion.img
                          style={{ cursor: 'pointer' }}
                          className="upload-button"
                          src={isHoverUploadButton ? uploadButtonHoverSVG : uploadButtonNormalSVG}
                          alt="upload-button"
                          onHoverStart={() => setIsHoverUploadButton(true)}
                          onHoverEnd={() => setIsHoverUploadButton(false)}
                        />
                        <div style={{ color: 'white', fontWeight: '300' }}>
                          Please upload any audio file (wav, mp3 and aiff) of 4 seconds to 6 minutes
                          length
                        </div>
                      </div>
                      <div className="select-library-button-container">
                        <BigRoundedButton
                          icon={<LibraryMusicIcon />}
                          isSvg={false}
                          text={currentSampleLibrary?.name}
                          width="12em"
                          height="auto"
                          fontSize="14px"
                          margin="0px"
                          padding="1em"
                          backgroundColor="#3F4043"
                          fontWeight="200"
                          onClick={() => setIsOpenSelectLibraryDropDown((value) => !value)}
                          isLoading={isLoadingSampleLibrary}
                        />
                        {isOpenSelectLibraryDropDown && (
                          <LibrarySelectDropDown
                            setCurrentSampleLibrary={setCurrentSampleLibrary}
                            top="109%"
                            right="0"
                            width="100%"
                            setIsOpenLibrarySelectDropDown={setIsOpenSelectLibraryDropDown}
                            sampleLibraries={sampleLibraries}
                          />
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <RoundedTextInfo text="You are on mobile, please come back on another device." />
                    </>
                  )}
                </>
              )}{' '}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default UploadMainPage;
