import './LoadingGIFWithText.css';
import PropTypes from 'prop-types';
import loadingGIF from '../../assets/loading-gif.gif';

LoadingGIFWithText.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  text: PropTypes.string
};

export default function LoadingGIFWithText({ width, height, text }) {
  return (
    <>
      {text && <div className="processing-text">{text}</div>}
      <img
        style={{ width: width ? width : '100%', height: height ? height : '100%' }}
        src={loadingGIF}
        alt="loading-gif"
      />
    </>
  );
}
