/* eslint-disable no-unused-vars */
import { useEffect, useState, useLayoutEffect, useCallback, useRef } from 'react';
import { useQuery } from 'react-query';

import PropTypes from 'prop-types';

import { filtersListValues } from '../../constants/FiltersInfos';

import { fetchSampleAudioFile } from '../../fetchers/fetchers';

import useArrowPressed from '../../hooks/UseArrowPressed';

import { useKeyPress } from '../../hooks/UseKeyPress';

import { mapKeysToIndex } from '../../constants/MapKeysToIndex';
import { mapKeysToIndexQWERTYLabels } from '../../constants/MapKeysToIndex';
import { motion } from 'framer-motion';

import FiltersDialog from '../FiltersDialog/FiltersDialog';
import CustomSnackBarError from '../CustomSnackBarError/CustomSnackBarError';
import ListOfSamplesHeader from './ListOfSamplesHeader';
import ListOfSamplesBody from './ListOfSamplesBody';
import ListOfSamplesFooter from './ListOfSamplesFooter';

import './ListOfSamples.css';

import { isMobile } from 'react-device-detect';
import findRecursivelyFileHandle from '../../lib/findRecursivelyFileHandler';

import axios from 'axios';
import { ApiRouteSampleBlob, ApiRouteSampleInformation } from '../../constants/ApiRoutes';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

ListOfSamples.propTypes = {
  numberOfItemsPerPage: PropTypes.number.isRequired,
  isMatchingProcessFinished: PropTypes.bool.isRequired,
  totalNumberOfSamples: PropTypes.number.isRequired,
  setTotalNumberOfSamples: PropTypes.func.isRequired,
  loadingMatchingProcess: PropTypes.bool.isRequired,
  samplesLoaded: PropTypes.array,
  setSamplesLoaded: PropTypes.func,
  setDrumKitListOfSamplesToPlay: PropTypes.func,
  isFrenchKeyBoard: PropTypes.bool,
  setCurrentBlobPlayed: PropTypes.func,
  currentSampleLibrary: PropTypes.any.isRequired,
  setCurrentSampleLibrary: PropTypes.func.isRequired,
  sampleLibrairies: PropTypes.array.isRequired
};

function ListOfSamples({
  numberOfItemsPerPage,
  isMatchingProcessFinished,
  totalNumberOfSamples,
  setTotalNumberOfSamples,
  loadingMatchingProcess,
  samplesLoaded,
  setSamplesLoaded,
  setDrumKitListOfSamplesToPlay,
  isFrenchKeyBoard,
  setCurrentBlobPlayed,
  currentSampleLibrary,
  setCurrentSampleLibrary,
  sampleLibrairies
}) {
  /* Total number of pages for pagination */
  const [numberPages, setNumberPages] = useState();

  /* The index of the current page selected */
  const [currentPageSelected, setCurrentPageSelected] = useState(1);

  // /* The current url of the track playing */
  // const [currentUrlPlaying, setCurrentUrlPlaying] = useState('');

  /* The index in the list 'samplesLoaded' of the track playing */
  const [indexOfTrackPlaying, setIndexOfTrakPlaying] = useState(0);

  /* An array of the different filter activated or not. Ex : ['ki', 'sn'] means that kick and snare are selected */
  const [filterButtonsValues, setFilterButtonsValues] = useState([]);

  /* The name of the file to play */
  const [filenameToPlay, setFilenameToPlay] = useState();

  /* Boolean to know if we are in pagination mode or scroll mode */
  const [paginationSwitch, setPaginationSwitch] = useState(isMobile ? false : true);

  /* The error message to print when axios gets an error from the server */
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  /* A object mapping a key to a pad index, it changes when the keyboard language is changed */
  const [currentMapKeysToIndex, setCurrentMapKeysToIndex] = useState(mapKeysToIndexQWERTYLabels);

  /* A boolean to know if the filter dialog should be open or not */
  const [isOpenFilterDialog, setIsOpenFilterDialog] = useState(false);

  /* A boolean to know if the select library drop down is open */
  const [isOpenSelectLibraryDropDown, setIsOpenSelectLibraryDropDown] = useState(false);

  /* Elements use to handle infite scroll */
  const [upRangeWhenScroll, setUpRangeWhenScroll] = useState(0);
  const [hasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [distanceBottom, setDistanceBottom] = useState(0);
  const tableEl = useRef();

  /* A state to know if we should play sample when changing pages */
  const [shouldPlaySampleNextPage, setShouldPlaySampleNextPage] = useState({
    status: false,
    value: ''
  });

  /* Hooks to get arrow pressed events */
  const arrowUpPressed = useArrowPressed('ArrowUp');
  const arrowDownPressed = useArrowPressed('ArrowDown');

  /* A ref to the current element selected in the list of samples */
  const arrowSelected = useRef(null);

  /* The timer used as a debouncer to handle play sample when navigating in the list with arrows */
  const [lastArrowInputTime, setLastArrowInputTime] = useState(null);

  /* A boolean to know if the web interface is currently fetching the next page of the list of samples */
  const [isLoadingFetchingNextPage, setIsLoadingFetchingNextPage] = useState(false);

  const audioRef = useRef(null);

  // Prevent arrow keys and space bar to act on scrolling bar or focused buttons
  window.addEventListener(
    'keydown',
    function (e) {
      if (['Space', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].indexOf(e.code) > -1) {
        e.preventDefault();
      }
    },
    false
  );

  /*********************************  A set of function to handle infinite scroll  *****************************/

  const loadMore = useCallback(() => {
    const loadItems = async () => {
      await new Promise((resolve) =>
        setTimeout(() => {
          fetchSamplesInfosByRangeAndFilters(1, upRangeWhenScroll + 30);
          resolve();
        }, 500)
      );
    };
    loadItems();
  }, [samplesLoaded]);

  const scrollListener = useCallback(() => {
    let bottom = tableEl.current.scrollHeight - tableEl.current.clientHeight;
    if (!distanceBottom) {
      setDistanceBottom(Math.round((bottom / 100) * 20));
    }
    if (
      tableEl.current.scrollTop > bottom - distanceBottom &&
      hasMore &&
      !paginationSwitch &&
      totalNumberOfSamples > upRangeWhenScroll
    ) {
      setUpRangeWhenScroll((value) => value + 30);
      setLoading(true);
      loadMore();
    }
  }, [hasMore, loadMore, loading, distanceBottom]);

  useLayoutEffect(() => {
    const tableRef = tableEl.current;
    if (tableRef) tableRef.addEventListener('scroll', scrollListener);
    return () => {
      if (tableRef) tableRef.removeEventListener('scroll', scrollListener);
    };
  }, [scrollListener]);

  /*********************************  End of a set of function to handle infinite scroll  *****************************/

  const setEnabledForUseQuerry = () => {
    if (filenameToPlay === undefined) {
      return false;
    }
  };

  const getSampleCacheTime = useCallback(() => {
    return currentSampleLibrary.cache ? 5000 : 0;
  }, [currentSampleLibrary]);

  /* Fetch a sample audio file and set it in the cache */
  const {
    data: track,
    isError: isErrorQuery,
    error: errorQuery,
    isFetching: isFetchingAudioBlob
  } = useQuery(
    [filenameToPlay],
    () => fetchSampleAudioFile(filenameToPlay, currentSampleLibrary.name.toLowerCase()),
    {
      staleTime: Infinity,
      cacheTime: getSampleCacheTime(),
      enabled: setEnabledForUseQuerry()
    }
  );

  useEffect(() => {
    isFetchingAudioBlob && setLoading(true);
    !isFetchingAudioBlob && setLoading(false);
  }, [isFetchingAudioBlob]);

  /* Changing the current map keyboard key to index when the language button of the drumkit is selected */
  useEffect(() => {
    isFrenchKeyBoard
      ? setCurrentMapKeysToIndex(mapKeysToIndex)
      : setCurrentMapKeysToIndex(mapKeysToIndexQWERTYLabels);
  }, [isFrenchKeyBoard]);

  /* Wait for the up arrow to be pressed */
  useEffect(() => {
    if (arrowUpPressed && !isLoadingFetchingNextPage) {
      /* Case we need to change page or don't move the index because index is already on the top of the list */
      if (indexOfTrackPlaying <= 0) {
        if (currentPageSelected !== 1 && paginationSwitch) {
          setCurrentPageSelected((currentPage) => currentPage - 1);
          setShouldPlaySampleNextPage({ status: true, value: 'up' });
          setIndexOfTrakPlaying(numberOfItemsPerPage - 1);
        }
      } else {
        /* Normal case */
        setIndexOfTrakPlaying((index) => index - 1);
        /* Play the sample if debouncer is ok */
        // if (!lastArrowInputTime || Date.now() - lastArrowInputTime > 300) {
        //   handleClickOnSampleFromListOfSample(indexOfTrackPlaying - 1);
        // }
      }
      // setLastArrowInputTime(Date.now());
    }
  }, [arrowUpPressed]);

  /* Wait for the down arrow to be pressed */
  useEffect(() => {
    if (arrowDownPressed && !isLoadingFetchingNextPage) {
      /* Case we need to change page or don't move the index beacause the index is already at the bottom of the list */
      if (indexOfTrackPlaying >= samplesLoaded.length - 1) {
        if (currentPageSelected !== numberPages && paginationSwitch) {
          setCurrentPageSelected((currentPage) => currentPage + 1);
          setIndexOfTrakPlaying(0);
          setShouldPlaySampleNextPage({ status: true, value: 'down' });
        }
      } else {
        /* Normal case */
        setIndexOfTrakPlaying((index) => index + 1);
        /* Play the sample if debouncer is ok */
        // if (!lastArrowInputTime || Date.now() - lastArrowInputTime > 300) {
        //   console.log('arrow down call handle click');
        //   handleClickOnSampleFromListOfSample(indexOfTrackPlaying + 1);
        // }
      }
      // setLastArrowInputTime(Date.now());
    }
  }, [arrowDownPressed]);

  /* This is a function triggered when we change page to know if we should play the last of the first sample of the page chosen */
  useEffect(() => {
    if (samplesLoaded) {
      if (shouldPlaySampleNextPage.status) {
        shouldPlaySampleNextPage.value === 'up'
          ? handleClickOnSampleFromListOfSample(numberOfItemsPerPage - 1)
          : handleClickOnSampleFromListOfSample(0);
        setShouldPlaySampleNextPage({ status: false, value: '' });
      }
    }
  }, [samplesLoaded]);

  // Play the sample when arrow are hit, with a debouncer to prevent fast navigation issues
  useEffect(() => {
    if (samplesLoaded[indexOfTrackPlaying] === undefined) {
      return;
    }
    const timeout = setTimeout(() => {
      if (shouldPlaySampleNextPage.status !== true)
        handleClickOnSampleFromListOfSample(indexOfTrackPlaying);
    }, 150);
    return () => {
      clearTimeout(timeout);
    };
  }, [arrowDownPressed, arrowUpPressed]);

  /* When user changes library, set the current page selected and the index of track playing to the begining */
  useEffect(() => {
    setIndexOfTrakPlaying(0);
    setCurrentPageSelected(1);
    setFilterButtonsValues([]);
  }, [currentSampleLibrary]);

  /* A function triggered when the user changes the current page of the list of samples to fetch the next page with the right boundaries */
  useEffect(() => {
    if (isMatchingProcessFinished) {
      setIsLoadingFetchingNextPage(true);
      fetchSamplesInfosByRangeAndFilters(
        numberOfItemsPerPage * currentPageSelected - (numberOfItemsPerPage - 1),
        numberOfItemsPerPage * currentPageSelected
      );
    }
  }, [currentPageSelected, isMatchingProcessFinished]);

  /* Set the arrow selected element always in the middle of the screen */
  useEffect(() => {
    setCursorInTheMiddle();
  }, [indexOfTrackPlaying, isLoadingFetchingNextPage]);

  const setCursorInTheMiddle = useCallback(() => {
    const select = arrowSelected?.current?.querySelector('.sample-name-selected');
    if (select) {
      select.scrollIntoView({
        behaviour: 'smooth',
        block: 'center'
      });
    }
  }, [indexOfTrackPlaying, isLoadingFetchingNextPage]);

  /* track is the result of the useQuery, the new audio track to play*/
  useEffect(() => {
    if (track) {
      const url = URL.createObjectURL(track);
      audioRef.current.src = url;
      audioRef.current.load();
      audioRef.current.play();
      setCurrentBlobPlayed(track);
    }
  }, [track]);

  const stringifyFilters = () => {
    return filterButtonsValues.length === 0
      ? filtersListValues.toString()
      : filterButtonsValues.toString();
  };

  /* Fetch all the samples infos from the server with lower, upperBound and filters parameters */
  const fetchSamplesInfosByRangeAndFilters = (lowerBound, upperBound) => {
    setLoading(true);
    axios
      .get(`${API_ENDPOINT}${ApiRouteSampleInformation}`, {
        params: { lower_bound: lowerBound, upper_bound: upperBound, filters: stringifyFilters() },
        headers: {
          'User-Id': localStorage.getItem('user_id'),
          'Library-Name': currentSampleLibrary.name.toLowerCase()
        }
      })
      .then((res) => {
        setSamplesLoaded(res.data.ordered_data);
        setTotalNumberOfSamples(res.data.tags_nb.total);
        setLoading(false);
        setIsLoadingFetchingNextPage(false);
      })
      .catch(() => {
        setIsError(true);
        setLoading(false);
        setIsLoadingFetchingNextPage(false);
        setErrorMessage('Error while getting samples');
        setSamplesLoaded([]);
      });
  };

  /* A function to calculate the number of pages */
  useEffect(() => {
    const newNumberOfPages = totalNumberOfSamples / numberOfItemsPerPage;
    const tmp = Math.ceil(newNumberOfPages);
    setNumberPages(tmp);
  }, [numberOfItemsPerPage, totalNumberOfSamples]);

  /* A useEffect to fetch the samples when filter buttons changes wether we are in pagination or scroll mode */
  useEffect(() => {
    if (isMatchingProcessFinished) {
      setUpRangeWhenScroll(numberOfItemsPerPage);
      isMatchingProcessFinished && fetchSamplesInfosByRangeAndFilters(1, numberOfItemsPerPage);
    }
  }, [isMatchingProcessFinished, numberOfItemsPerPage, filterButtonsValues]);

  const playAudio = (url) => {
    if (url) {
      audioRef.current.src = url;
    }
    audioRef.current.load();
    audioRef.current.play();
  };

  const handlePlaySampleCustomLibrary = async (index) => {
    if (samplesLoaded[index] === undefined)
      return console.log('Error while playing sample, sample is undefined');
    const fileHandler = await findRecursivelyFileHandle(
      currentSampleLibrary.directoryHandler,
      samplesLoaded[index].filename
    );
    if (fileHandler.length === 0) {
      setIsError(true);
      setErrorMessage(
        'It seems that Sample Match cannot access your local sample library. Try to upload again your json file and verify the location of your sample library.'
      );
      return;
    }
    const realFile = await fileHandler[0].getFile();
    const url = URL.createObjectURL(realFile);
    playAudio(url);
    setCurrentBlobPlayed(realFile);
  };

  const handlePlaySampleRegularLibrary = (index) => {
    setIndexOfTrakPlaying(index);
    setFilenameToPlay(samplesLoaded[index]?.filename);
    if (filenameToPlay === samplesLoaded[index]?.filename) {
      playAudio();
    }
  };

  /* Function called when we want to play a sample by a click on play icon or by hitting enter key */
  const handleClickOnSampleFromListOfSample = (index) => {
    currentSampleLibrary?.custom
      ? handlePlaySampleCustomLibrary(index)
      : handlePlaySampleRegularLibrary(index);
  };

  // /* Function called when we change page with the arrows */
  // const onChangeCurrentPage = (status) => {
  //   if (status === 'up' && currentPageSelected !== 1) {
  //     setIndexOfTrakPlaying(0);
  //     setCurrentPageSelected((value) => value - 1);
  //   }
  //   if (status === 'down' && currentPageSelected !== numberPages) {
  //     setIndexOfTrakPlaying(0);
  //     setCurrentPageSelected((value) => value + 1);
  //   }
  // };

  /* Function called when we change page of list of sample */
  const onChangePageSelectedFromPagination = useCallback(
    (event, value) => {
      setIndexOfTrakPlaying(0);
      setCurrentPageSelected(value);
    },
    [indexOfTrackPlaying, currentPageSelected]
  );

  /* Function called when cick on the filters button to change the state */
  const handleChangeFilterButtons = (newValues) => {
    setIsLoadingFetchingNextPage(true);
    scrollRefHandler.current.scrollToIndex({ index: 0 });
    setIndexOfTrakPlaying(0);
    setCurrentPageSelected(1);
    if (newValues === 'clear') {
      setFilterButtonsValues([]);
      return;
    }
    if (filterButtonsValues.includes(newValues)) {
      const t = filterButtonsValues.filter((item) => item !== newValues);
      setFilterButtonsValues(t);
    } else {
      const tmp = [...filterButtonsValues, newValues];
      setFilterButtonsValues(tmp);
    }
  };

  /* Function called when we hit the pagination swith button to change between pagination and scrool mode */
  const onChangePaginationSwitch = useCallback(() => {
    setIndexOfTrakPlaying(0);
    setPaginationSwitch((state) => !state);
    setUpRangeWhenScroll(numberOfItemsPerPage);
    fetchSamplesInfosByRangeAndFilters(1, numberOfItemsPerPage);
  }, []);

  /* Function called when we click on the download icon on a sample to handle the downloading of this sample */
  const handleClickOnDownloadFromListOfSample = useCallback(
    async (filename) => {
      let url;
      if (currentSampleLibrary.custom) {
        const dirHandle = await currentSampleLibrary.directoryHandler;
        let fileHandler;
        for await (let [name, handle] of dirHandle) {
          if (name === filename && handle.kind === 'file') {
            fileHandler = handle;
          }
        }
        const realFile = await fileHandler.getFile();
        url = URL.createObjectURL(realFile);
      } else {
        const data = await fetchSampleAudioFile(filename, currentSampleLibrary.name.toLowerCase());
        url = URL.createObjectURL(data);
      }
      let a = document.createElement('a');
      a.href = url;
      a.download = filename;
      a.click();
      URL.revokeObjectURL(url);
    },
    [currentSampleLibrary]
  );

  const fetchSampleAndAddItToDrumKit = (event) => {
    axios
      .get(`${API_ENDPOINT}${ApiRouteSampleBlob}`, {
        params: { sample_name: samplesLoaded[indexOfTrackPlaying].filename },
        responseType: 'blob',
        headers: {
          'User-Id': localStorage.getItem('user_id'),
          'Library-Name': currentSampleLibrary.name.toLowerCase()
        }
      })
      .then((response) => {
        const url = URL.createObjectURL(response.data);
        setDrumKitListOfSamplesToPlay((drumKitListOfSamplesToPlay) => ({
          ...drumKitListOfSamplesToPlay,
          [currentMapKeysToIndex.get(event.key.toLowerCase())]: {
            name: samplesLoaded[indexOfTrackPlaying].filename,
            tag: samplesLoaded[indexOfTrackPlaying].tag,
            url: url,
            status: 'success',
            blob: response.data
          }
        }));
      })
      .catch(() => {
        setIsError(true);
        setErrorMessage('Error while assigning sample to drumkit');
        setDrumKitListOfSamplesToPlay((drumKitListOfSamplesToPlay) => ({
          ...drumKitListOfSamplesToPlay,
          [currentMapKeysToIndex.get(event.key.toLowerCase())]: {
            name: '',
            tag: '',
            url: '',
            status: 'success'
          }
        }));
      });
  };

  const getSampleFromUserDirectoryAndAddItToDrumKit = async (event) => {
    const file = await findRecursivelyFileHandle(
      currentSampleLibrary.directoryHandler,
      samplesLoaded[indexOfTrackPlaying].filename
    );
    if (file.length === 0) {
      setIsError(true);
      setErrorMessage('Error while assigning sample to drumkit');
      setDrumKitListOfSamplesToPlay((drumKitListOfSamplesToPlay) => ({
        ...drumKitListOfSamplesToPlay,
        [currentMapKeysToIndex.get(event.key.toLowerCase())]: {
          name: '',
          tag: '',
          url: '',
          status: 'success'
        }
      }));
    }
    const realFile = await file[0].getFile();
    const url = URL.createObjectURL(realFile);
    setDrumKitListOfSamplesToPlay((drumKitListOfSamplesToPlay) => ({
      ...drumKitListOfSamplesToPlay,
      [currentMapKeysToIndex.get(event.key.toLowerCase())]: {
        name: samplesLoaded[indexOfTrackPlaying].filename,
        tag: samplesLoaded[indexOfTrackPlaying].tag,
        url: url,
        status: 'success'
      }
    }));
  };

  const handleKeyPressed = (event) => {
    /* Play sample if we hit spacebar */
    if (event.keyCode === 32) handleClickOnSampleFromListOfSample(indexOfTrackPlaying);
    /* Assign to drumkit when we hit shift key */
    if (event.shiftKey) {
      /* Fetch sample for drum kit and assigned the url blob to the drumkit list of samples to play */
      setDrumKitListOfSamplesToPlay((drumKitListOfSamplesToPlay) => ({
        ...drumKitListOfSamplesToPlay,
        [currentMapKeysToIndex.get(event.key.toLowerCase())]: {
          name: '',
          tag: '',
          url: '',
          status: 'loading'
        }
      }));
      currentSampleLibrary.custom
        ? getSampleFromUserDirectoryAndAddItToDrumKit(event)
        : fetchSampleAndAddItToDrumKit(event);
    }
  };

  useKeyPress(['A', 'Z', 'E', 'R', 'Q', 'S', 'D', 'W'], handleKeyPressed, [32]);

  const scrollRefHandler = useRef();

  return (
    <>
      <CustomSnackBarError
        open={isError || isErrorQuery}
        onClose={() => setIsError(false)}
        severity="error"
        text={isError ? errorMessage : errorQuery}
      />
      <div className="list-of-samples-container" ref={arrowSelected}>
        <motion.div
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
          className="list-of-samples-border-container">
          <audio ref={audioRef} />
          {isOpenFilterDialog && (
            <FiltersDialog
              handleChangeFilterButtons={handleChangeFilterButtons}
              filtersButtonsValues={filterButtonsValues}
              setIsOpenFilterDialog={setIsOpenFilterDialog}
              scrollRefHandler={scrollRefHandler}
            />
          )}
          <ListOfSamplesHeader
            isMatchingProcessFinished={isMatchingProcessFinished}
            setIsOpenFilterDialog={setIsOpenFilterDialog}
            isFetchingAudioBlob={isFetchingAudioBlob}
            isOpenSelectLibraryDropDown={isOpenSelectLibraryDropDown}
            setIsOpenSelectLibraryDropDown={setIsOpenSelectLibraryDropDown}
            currentSampleLibrary={currentSampleLibrary}
            setCurrentSampleLibrary={setCurrentSampleLibrary}
            sampleLibraries={sampleLibrairies}
          />
          <ListOfSamplesBody
            tableEl={tableEl}
            isMatchingProcessFinished={isMatchingProcessFinished}
            isOpenFilterDialog={isOpenFilterDialog}
            isOpenSelectLibraryDropDown={isOpenSelectLibraryDropDown}
            samplesLoaded={samplesLoaded}
            indexOfTrackPlaying={indexOfTrackPlaying}
            loadingMatchingProcess={loadingMatchingProcess}
            handleClickOnDownloadFromListOfSample={handleClickOnDownloadFromListOfSample}
            handleClickOnSampleFromListOfSample={handleClickOnSampleFromListOfSample}
            isLoadingFetchingNextPage={isLoadingFetchingNextPage}
            isFetchingAudioBlob={isFetchingAudioBlob}
            currentSampleLibrary={currentSampleLibrary}
            scrollRefHandler={scrollRefHandler}
            paginationSwitch={paginationSwitch}
            currentPageSelected={currentPageSelected}
          />
          <ListOfSamplesFooter
            currentPageSelected={currentPageSelected}
            onChangeCurrentPage={onChangePageSelectedFromPagination}
            numberPages={numberPages}
            totalNumberOfSamples={totalNumberOfSamples}
            onChangePaginationSwitch={onChangePaginationSwitch}
            paginationSwitch={paginationSwitch}
            isLoadingMatchingProcess={loadingMatchingProcess}
          />
        </motion.div>
      </div>
    </>
  );
}

export default ListOfSamples;
