import './CosineSimilarityFromSample.css';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

CosineSimilarityFromSample.propTypes = {
  sampleCosineSimilarity: PropTypes.number
};

function convertToPercentage(value) {
  const percentage = Math.round((value + 1) * 50);
  return `${percentage}%`;
}

function CosineSimilarityFromSample({ sampleCosineSimilarity }) {
  const color = useMemo(() => {
    if (sampleCosineSimilarity < 0) {
      return '#E13A05';
    } else if (sampleCosineSimilarity > 0 && sampleCosineSimilarity < 0.5) {
      return '#D19F53';
    } else if (sampleCosineSimilarity > 0.5) {
      return '#5EA094';
    }
  }, [sampleCosineSimilarity]);

  const percentage = useMemo(
    () => convertToPercentage(sampleCosineSimilarity),
    [sampleCosineSimilarity]
  );

  return (
    <div className="cosine-similarity-wrapper">
      <div
        className="cosine-similarity-filler"
        style={{ backgroundColor: color, width: percentage }}></div>
    </div>
  );
}

export default CosineSimilarityFromSample;
