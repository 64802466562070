async function findRecursivelyFileHandle(dirHandle, sampleName) {
  const fileHandler = [];
  for await (let [name, handle] of dirHandle) {
    if (handle.kind === 'directory') {
      fileHandler.push(...(await findRecursivelyFileHandle(handle, sampleName)));
    } else {
      if (name == sampleName) {
        fileHandler.push(handle);
      }
    }
  }
  return fileHandler;
}

export default findRecursivelyFileHandle;
