import { useEffect, useRef } from 'react';
import './LibrarySelectDropDown.css';
import LibrarySelectDropDownItem from './LibrarySelectDropDownItem';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import delay from '../../lib/delay';
import UploadCustomLibrary from '../UploadComponents/UploadCustomLibrary';
import { useCustomContext } from '../../contexts/customContext';

LibrarySelectDropDown.propTypes = {
  top: PropTypes.string,
  right: PropTypes.string,
  width: PropTypes.string,
  setCurrentSampleLibrary: PropTypes.func.isRequired,
  setIsOpenLibrarySelectDropDown: PropTypes.func.isRequired,
  sampleLibraries: PropTypes.array
};

function LibrarySelectDropDown({
  top,
  right,
  width,
  setCurrentSampleLibrary,
  setIsOpenLibrarySelectDropDown,
  sampleLibraries
}) {
  const ref = useRef();

  const { setSampleLibraries } = useCustomContext();

  useEffect(() => {
    const handleClick = async (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        await delay(100);
        setIsOpenLibrarySelectDropDown(false);
      }
    };
    document.addEventListener('click', handleClick, true);
    return () => document.removeEventListener('click', handleClick, true);
  });
  const onSelectSampleLibrary = async (library) => {
    try {
      if (library.custom && !library?.directoryHandler) {
        const directory = await window.showDirectoryPicker();
        const newSamplesLibraries = sampleLibraries.map((lib) => {
          if (lib.name === library.name) {
            return { ...lib, directoryHandler: directory };
          } else {
            return lib;
          }
        });
        const newLibrarySelected = { ...library, directoryHandler: directory };
        setCurrentSampleLibrary(newLibrarySelected);
        setSampleLibraries(newSamplesLibraries);
      } else {
        setCurrentSampleLibrary(library);
      }
      setIsOpenLibrarySelectDropDown(false);
    } catch (error) {
      alert('Please select a directory to upload your samples.');
    }
  };

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  return (
    <motion.div
      animate="show"
      initial="hidden"
      className="library-select-drop-down-main-container"
      style={{ top: top && top, right: right && right, width: width && width }}
      ref={ref}
      variants={container}>
      {sampleLibraries?.map((library) => (
        <LibrarySelectDropDownItem
          onSelectItem={onSelectSampleLibrary}
          key={library?.name}
          library={library}
        />
      ))}
      {process.env.REACT_APP_ALLOW_CUSTOM_LIBRARY === 'true' ? (
        <UploadCustomLibrary text="Add one" />
      ) : (
        <></>
      )}
    </motion.div>
  );
}

export default LibrarySelectDropDown;
