import PropTypes from 'prop-types';

import UploadAndPlay from '../UploadComponents/UploadAndPlay';
// import sampleMatchLogoSVG from '../../assets/sample-match-logo.svg';
import sampleMatchLogoSVG from '../../assets/unregistered-sample-match-logo.svg';
import './Header.css';
import sonyLogoSVG from '../../assets/sony-logo.svg';
import BigRoundedButton from '../Buttons/BigRoundedButton';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useMemo, useState } from 'react';
import HelperTextContainer from './HelperTextContainer';
import { isMobileOnly, useDeviceSelectors, useMobileOrientation } from 'react-device-detect';

Header.propTypes = {
  setIsFileUploaded: PropTypes.func.isRequired,
  isFileUploaded: PropTypes.bool.isRequired,
  setIsLoadingUploadingFile: PropTypes.func.isRequired,
  setIsMatchingProcessFinished: PropTypes.func.isRequired
};

function Header({
  setIsFileUploaded,
  isFileUploaded,
  setIsLoadingUploadingFile,
  setIsMatchingProcessFinished
}) {
  const [isOpenHelperContainer, setIsOpenHelperContainer] = useState(false);

  const [selectors] = useDeviceSelectors(window.navigator.userAgent);

  const { isMobile } = selectors;
  const { isLandscape } = useMobileOrientation();

  const helperText = useMemo(() => {
    return isFileUploaded ? (
      <div>
        Below is a list of samples computed by the Sample Match model.
        <br />
        The colored bar indicates matching accuracy. <br />
        <br />
        <strong> Drag and drop </strong> a sample to the desired drum pad in your drum kit to try it
        out. <br />
        Or, once a sample is selected press <strong>Shift + Drum pad keyboard key</strong> to assign
        the selected sample to the corresponding drum pad.
      </div>
    ) : (
      <div>
        Select a default sample library or add your own local sample library. <br />
        Then, upload any audio file of 4 seconds to 6 minutes length.
      </div>
    );
  }, [isFileUploaded]);
  return (
    <div className="header" style={{ height: isMobile && isLandscape && isFileUploaded && '15%' }}>
      <div className="sony-header">
        {process.env.REACT_APP_SONY_TRADEMARKS === 'true' ? (
          <div className="sony-header">
            <img className="sony-logo" src={sonyLogoSVG} alt="sony-logo" />
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="header-border-container">
        {isMobile && isFileUploaded ? (
          <></>
        ) : (
          <div className="logo-and-sample-match-name-container">
            <img className="sample-match-logo" src={sampleMatchLogoSVG} alt="sample-match-logo" />
          </div>
        )}

        <UploadAndPlay
          setIsFileUploaded={setIsFileUploaded}
          isFileUploaded={isFileUploaded}
          setIsLoadingUploadingFile={setIsLoadingUploadingFile}
          setIsMatchingProcessFinished={setIsMatchingProcessFinished}
        />
        {!isMobileOnly ? (
          <div className="header-left-container">
            <div className="header-buttons-container">
              {isOpenHelperContainer && (
                <HelperTextContainer text={helperText} setIsOpen={setIsOpenHelperContainer} />
              )}
              <BigRoundedButton
                icon={<HelpOutlineIcon />}
                text={'HELP'}
                onClick={() => setIsOpenHelperContainer((value) => !value)}
                width="6em"
                height="50%"
                isSvg={false}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default Header;
