/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import './ListOfSamplesFooter.css';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Pagination } from '@mui/material';
import { memo } from 'react';

ListOfSamplesFooter.propTypes = {
  currentPageSelected: PropTypes.number.isRequired,
  onChangeCurrentPage: PropTypes.func.isRequired,
  numberPages: PropTypes.number,
  totalNumberOfSamples: PropTypes.number,
  onChangePaginationSwitch: PropTypes.func.isRequired,
  paginationSwitch: PropTypes.bool.isRequired,
  isLoadingMatchingProcess: PropTypes.bool
};

function ListOfSamplesFooter({
  currentPageSelected,
  onChangeCurrentPage,
  numberPages,
  totalNumberOfSamples,
  onChangePaginationSwitch,
  paginationSwitch,
  isLoadingMatchingProcess
}) {
  return (
    <div className="table-foot-container">
      {!isLoadingMatchingProcess && (
        <>
          {paginationSwitch && (
            <div className="footer-arrows-container">
              <Pagination
                style={{ padding: 0, width: 'auto' }}
                size="small"
                siblingCount={0}
                color="secondary"
                count={numberPages}
                page={currentPageSelected}
                onChange={onChangeCurrentPage}
                sx={{
                  '& .MuiPaginationItem-root': {
                    color: '#fff'
                  }
                }}
              />
            </div>
          )}
          <div className="footer-results-container">
            {totalNumberOfSamples} <span style={{ fontWeight: '200' }}>{' results'}</span>
          </div>
          <div className="footer-switch-container">
            <div
              onClick={() => onChangePaginationSwitch()}
              style={{ color: paginationSwitch ? '#625f6d' : 'white' }}>
              scroll
            </div>
            {'  |  '}
            <div
              onClick={() => onChangePaginationSwitch()}
              style={{ color: paginationSwitch ? 'white' : '#625f6d' }}>
              pagination
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default memo(ListOfSamplesFooter);
