import PropTypes from 'prop-types';
import './RoundedLogoButton.css';
import CustomToolTip from '../CustomToolTip/CustomToolTip';

RoundedLogoButton.propTypes = {
  icon: PropTypes.any,
  onClick: PropTypes.func,
  logoWidth: PropTypes.string,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  borderRadius: PropTypes.string
};

function RoundedLogoButton({ onClick, icon, logoWidth, title, disabled, borderRadius }) {
  return (
    <CustomToolTip enterDelay={900} title={title}>
      <div
        onClick={onClick}
        className={
          disabled === true
            ? 'rounded-button-container-disabled rounded-button-container'
            : 'rounded-button-container'
        }
        style={{ borderRadius: borderRadius ? borderRadius : '50%' }}>
        <img style={{ width: logoWidth ? logoWidth : '60%' }} src={icon} alt="icon" />
      </div>
    </CustomToolTip>
  );
}

export default RoundedLogoButton;
