import './HelperTextContainer.css';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { useEffect, useRef } from 'react';
import delay from '../../lib/delay';

HelperTextContainer.propTypes = {
  text: PropTypes.element.isRequired,
  setIsOpen: PropTypes.func.isRequired
};

function HelperTextContainer({ text, setIsOpen }) {
  const ref = useRef();
  /* Catch any click outside component */
  useEffect(() => {
    const handleClick = async (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        await delay(100);

        setIsOpen(false);
      }
    };
    document.addEventListener('click', handleClick, true);
    return () => document.removeEventListener('click', handleClick, true);
  });
  return (
    <motion.div
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      className="helper-text-main-container"
      ref={ref}>
      {text}
    </motion.div>
  );
}

export default HelperTextContainer;
