import { useEffect, useState } from 'react';
import './WaveFormContainer.css';
import PropTypes from 'prop-types';
import WaveForm from './WaveForm';
import RoundedTextInfo from '../TextContainer/RoundedTextInfo';
import { motion } from 'framer-motion';

WaveFormContainer.propTypes = {
  currentBlobPlayed: PropTypes.any,
  isMatchingProcessFinished: PropTypes.bool
};

function WaveFormContainer({ currentBlobPlayed, isMatchingProcessFinished }) {
  const [audioBuffer, setAudioBuffer] = useState();
  useEffect(() => {
    if (currentBlobPlayed) {
      const audioContext = new AudioContext();
      currentBlobPlayed
        .arrayBuffer()
        .then((array) =>
          audioContext.decodeAudioData(array, (newArray) => setAudioBuffer(newArray))
        );
    }
  }, [currentBlobPlayed]);
  return (
    <div className="wave-form-container-main-container">
      {isMatchingProcessFinished && (
        <motion.div
          className="wave-form-container-border-container"
          animate={{ opacity: 1, scale: 1 }}
          initial={{ opacity: 0, scale: 0.8 }}
          transition={{ duration: 1, delay: 0.5, ease: [0, 0.71, 0.2, 1.01] }}>
          <div className="wave-form-border-container">
            {isMatchingProcessFinished ? (
              currentBlobPlayed ? (
                <WaveForm audioBuffer={audioBuffer} width={1000} height={1000} />
              ) : (
                <RoundedTextInfo text="Play a sample to see its waveform" />
              )
            ) : (
              <>
                <RoundedTextInfo text="Sample matching in progress ..." />
              </>
            )}
          </div>
        </motion.div>
      )}
    </div>
  );
}

export default WaveFormContainer;
