import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

WaveForm.propTypes = {
  audioBuffer: PropTypes.any,
  width: PropTypes.any,
  height: PropTypes.any,
  bgColor: PropTypes.string
};

function WaveForm({ audioBuffer, width, height, bgColor }) {
  const canvasRef = useRef(null);

  useEffect(() => {
    if (!audioBuffer) return;

    const canvas = canvasRef.current;
    const canvasCtx = canvas.getContext('2d');
    const bufferLength = audioBuffer.length;
    const sliceWidth = (width * 1.0) / bufferLength;
    let x = 0;

    canvasCtx.clearRect(0, 0, width, height);
    canvasCtx.fillStyle = '#1A191E';
    canvasCtx.fillRect(0, 0, width, height);

    canvasCtx.beginPath();
    canvasCtx.strokeStyle = '#AA4686';
    canvasCtx.lineWidth = 1;

    for (let i = 0; i < bufferLength; i++) {
      const y = (audioBuffer.getChannelData(0)[i] + 1) * height * 0.5;
      if (i === 0) {
        canvasCtx.moveTo(x, y);
      } else {
        canvasCtx.lineTo(x, y);
      }
      x += sliceWidth;
    }
    canvasCtx.stroke();

    // Draw lower line
    canvasCtx.beginPath();
    canvasCtx.strokeStyle = 'white';
    canvasCtx.lineWidth = 0.4;
    canvasCtx.moveTo(0, height * 0.25);
    canvasCtx.lineTo(width, height * 0.25);
    canvasCtx.stroke();

    // Draw the upper line
    canvasCtx.beginPath();
    canvasCtx.strokeStyle = 'white';
    canvasCtx.lineWidth = 0.4;
    canvasCtx.moveTo(0, height * 0.75);
    canvasCtx.lineTo(width, height * 0.75);
    canvasCtx.stroke();

    // Draw the middle line
    canvasCtx.beginPath();
    canvasCtx.strokeStyle = 'white';
    canvasCtx.lineWidth = 1;
    canvasCtx.moveTo(0, height * 0.5);
    canvasCtx.lineTo(width, height * 0.5);
    canvasCtx.stroke();
  }, [audioBuffer, width, height, bgColor]);

  return (
    <canvas
      ref={canvasRef}
      width={width}
      height={height}
      style={{ width: '100%', height: '100%', borderRadius: '10px' }}
    />
  );
}

export default WaveForm;
