/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import { cutWavEndOfString } from '../../constants/FilenameTools';

import './DraggableSampleName.css';
import CustomToolTip from '../CustomToolTip/CustomToolTip';

DraggableSampleName.propTypes = {
  sampleName: PropTypes.string,
  sampleType: PropTypes.string,
  index: PropTypes.number,
  isSelected: PropTypes.bool
};

function DraggableSampleName({ sampleName, sampleType, index, isSelected }) {
  const printSamplesInfos = () => {
    return 'Rank : ' + (index + 1);
  };

  return (
    <CustomToolTip enterDelay={1000} title={printSamplesInfos()}>
      <div className={isSelected ? 'sample-name-selected' : 'sample-name'}>
        <span
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }}>
          {cutWavEndOfString(sampleName)}
        </span>
      </div>
    </CustomToolTip>
  );
}

export default DraggableSampleName;
