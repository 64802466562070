import './RoundedTextInfo.css';
import ProptTypes from 'prop-types';

RoundedTextInfo.propTypes = {
  text: ProptTypes.string
};

function RoundedTextInfo({ text }) {
  return <div className="rounded-text-info-main-container">{text}</div>;
}

export default RoundedTextInfo;
